import React, { useContext } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileInvoice, faHome } from "@fortawesome/free-solid-svg-icons";
import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";
import { Image, Nav } from "react-bootstrap";
import Media from "react-bootstrap/Media";
import { LinkContainer } from "react-router-bootstrap";

import logo_url from "lib/images/logos/logo_cone.png";
import { colorStyles, iconGrey, lightSteel, salmon, steel } from "lib/css/colors";
import UserContext from "components/layout/UserContext";

const PROFILE_IMAGE =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTz0DnVgk-2qQYe-4MgFmbMfeZfk8qqkb0yhg&usqp=CAU";

function AppSideNavBar({ expanded, setExpanded }) {
  const user = useContext(UserContext);

  if (!expanded) {
    return null;
  }

  const getCompanyDomain = () => {
    return user?.login ? user?.ocr_user.email.split("@").pop() : "";
  };

  return (
    <div
      className={classNames(
        css(
          styles.container,
          !expanded && styles.containerCollapsed,
          expanded && styles.containerExpanded,
        ),
        "d-flex",
      )}
    >
      <Nav defaultActiveKey="/" className={css(styles.nav)}>
        <Nav.Link className={css(styles.logoLink)}>
          <Image className={css(styles.logo)} src={logo_url} />
        </Nav.Link>
        <Nav.Link className={css(styles.link, styles.name)}>
          <Media>
            <img
              className={classNames("mr-2", css(styles.circle))}
              src={PROFILE_IMAGE}
              alt="User Avatar"
            />
            <Media.Body className={css(styles.mediaBody)}>
              <div className={css(styles.mediaBodyComponents)}>{user?.ocr_user.name}</div>
              <div
                className={classNames(
                  "small",
                  css(styles.mediaBodyComponents, colorStyles.lightergrey),
                )}
              >
                {getCompanyDomain()}
              </div>
            </Media.Body>
          </Media>
        </Nav.Link>
        <LinkContainer to="/">
          <Nav.Link className={css(styles.link)}>
            <FontAwesomeIcon className={css(styles.icon)} icon={faHome} color={iconGrey} />
            Home
          </Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/invoice/dashboard"
          activeClassName={css(styles.activeLink, styles.activeIcon)}
        >
          <Nav.Link className={css(styles.link)}>
            <FontAwesomeIcon className={css(styles.icon)} icon={faFileInvoice} color={iconGrey} />
            Invoice
          </Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/order-guide/dashboard"
          activeClassName={css(styles.activeLink, styles.activeIcon)}
        >
          <Nav.Link className={css(styles.link)}>
            <FontAwesomeIcon className={css(styles.icon)} icon={faFileExcel} color={iconGrey} />
            Order Guide
          </Nav.Link>
        </LinkContainer>
      </Nav>
    </div>
  );
}

const translateKeyframes = {
  from: {
    left: "-100vw",
  },
  to: {
    left: 0,
  },
};

const translateKeyframesOut = {
  from: {
    left: 0,
  },
  to: {
    left: "-100vw",
  },
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: steel,
    height: "100%",
    left: "-100vw",
    maxWidth: 220,
    position: "fixed",
    top: 0,
    width: "80vw",
    zIndex: 1500, // high enough to be over the fade.
  },
  containerCollapsed: {
    animationName: [translateKeyframesOut],
    animationDuration: "600ms",
  },
  containerExpanded: {
    animationName: [translateKeyframes],
    animationDuration: "600ms",
    left: 0,
  },
  activeIcon: {
    color: "white",
  },
  activeLink: {
    backgroundColor: lightSteel,
    borderLeft: "2px solid " + salmon,
  },
  circle: {
    borderRadius: "50%",
    width: "25%",
  },
  icon: {
    marginRight: 5,
    width: 20,
  },
  link: {
    borderLeft: "2px solid " + steel,
    color: "white",
    ":hover": {
      backgroundColor: lightSteel,
    },
  },
  logo: {
    width: "100%",
  },
  logoLink: {
    padding: "1rem 2rem",
  },
  name: {
    marginBottom: 25,
  },
  mediaBody: {
    overflow: "hidden",
  },
  mediaBodyComponents: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  nav: {
    backgroundColor: steel,
    flexDirection: "column",
    maxWidth: 220,
    minHeight: "100%",
    minWidth: 220,
    animation: "margin-left .75s forwards",
    position: "fixed",
  },
});

AppSideNavBar.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
};

export default AppSideNavBar;
