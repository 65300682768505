import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

function ButtonGrouped({ buttons, size, btnGroupClassName }) {
  return (
    <ButtonGroup className={btnGroupClassName} aria-label={`${buttons}-group`}>
      {buttons.map((btn, index) => (
        <Button
          key={index}
          variant={btn.variant}
          size={size}
          className={`${btn.className} text-capitalize`}
          disabled={btn.disabled}
          onClick={btn.onHandle}
        >
          {btn.giphy && (
            <FontAwesomeIcon
              icon={btn.giphy.icon}
              className={`${btn.giphy.className} mx-1`}
            />
          )}
          {btn.text}
        </Button>
      ))}
    </ButtonGroup>
  );
}

ButtonGrouped.propTypes = {
  buttons: PropTypes.array.isRequired,
  size: PropTypes.string.isRequired,
  btnGroupClassName: PropTypes.string.isRequired
};

export default ButtonGrouped;
