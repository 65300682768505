import { INPUT_VALID_PATTERN, INPUT_TYPES } from 'lib/ui/enums/enums';

const getLineTotal = (items, index, mapping) => {
  const sum =
    Number(items[index][mapping.qty_shipped] || items[index][mapping.qty_ordered]) *
    Number(items[index][mapping.price]);
  return isNaN(sum) ? '0.00' : sum.toFixed(2);
};

const getSum = (items, field) => {
	let sum = 0;
	items.map((item) => {
		return (sum += Number(item[field]));
	});
	return isNaN(sum) ? '0.00' : sum.toFixed(2);
};

const getInputPattern = (type) => {
	switch (type.toLowerCase()) {
		case 'number':
			return INPUT_VALID_PATTERN.FLOAT_SEVEN_DECIMALS_PATTERN;
		case 'float':
			return INPUT_VALID_PATTERN.FLOAT_SEVEN_DECIMALS_PATTERN;
		case 'int':
			return INPUT_VALID_PATTERN.INTEGERS_PATTERN;
		case 'string':
			return INPUT_VALID_PATTERN.ANY_CHARACTER_PATTERN;
		default:
			return INPUT_VALID_PATTERN.ANY_CHARACTER_PATTERN;
	}
};

const getInputType = (type) => {
	if (type === INPUT_TYPES.DATE) {
		return INPUT_TYPES.DATE;
	} else {
		return INPUT_TYPES.TEXT;
	}
};

export { getLineTotal, getSum, getInputPattern, getInputType };
