export function useNewItem() {
  const EXISTING_OG_BOOL_COLUMNS = [
    "verified",
    "proper",
    "existing_og",
    "is_edited"
  ];
  const NEW_OG_BOOL_COLUMNS = ["verified", "proper"];

  //creates a sample line item with empty values
  const createSampleLineItem = (isExistingOG, lineItemNames) => {
    const booleanColumns = isExistingOG
      ? EXISTING_OG_BOOL_COLUMNS
      : NEW_OG_BOOL_COLUMNS;
    return lineItemNames.reduce(
      (o, key) => ({ ...o, [key]: booleanColumns.includes(key) ? false : "" }),
      {}
    );
  };
  //copy a specific line item with values
  const copyLineItem = (item, lineItemNames) => {
    return lineItemNames.reduce((o, key) => ({ ...o, [key]: item[key] }), {});
  };

  return [createSampleLineItem, copyLineItem];
}
