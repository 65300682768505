import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import DropZone from "components/shared/DropZone";

import multiDownload from "multi-download";
import { ExcelRenderer } from "react-excel-renderer";

import { GET_ORDER_GUIDE_SECTIONS } from "config/config";
import { useGet } from "lib/hooks/useGet";
import { useState } from "react";
import { MessageAlert, AreYouSureAlert } from "../../lib/ui/alert";
import { getDiff } from "../../lib/methods/arrayFunctions";
import { ogStatus } from "lib/ui/enums/enums";
import { SwalToast } from "lib/ui/toast";

import { Row, Col, Container, Form } from "react-bootstrap";
import { Button, Alert } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";

const FILE_SAVED_DOMAIN_1 =
  "https://ordering-supplies-images-1.s3.us-east-2.amazonaws.com/";

const FILE_SAVED_DOMAIN_2 = "https://s3.amazonaws.com/rotated.invoices.db/";

const EXCEL_HEADERS = [
  "Item Name",
  "Purchasing Unit",
  "Unit Price",
  "Item Code",
  "Accounting Category",
  "Par"
];

function ExcelUpload({ orderGuide, isClaimed, updateOrderGuide }) {
  const [file, setFile] = useState(null);
  const [fileLimitError, setFileLimitError] = useState(false);
  const [excelHeadersInvalid, setExcelHeadersInvalid] = useState(false);
  const [sections, sectionsFetchError] = useGet(GET_ORDER_GUIDE_SECTIONS);
  const [diffSections, setDiffSections] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);

  const validateSections = excelData => {
    const uploadedSections = excelData.map(data => {
      return data[1];
    });
    const diffSections = getDiff(uploadedSections, sections);
    return diffSections;
  };

  useEffect(() => {
    if (sectionsFetchError) {
      SwalToast("error", "Fetching sections failed", "top-end");
    }
  }, [sectionsFetchError]);

  const onDrop = useCallback(acceptedFiles => {
    setIsUploaded(false);
    setExcelHeadersInvalid(false);
    if (acceptedFiles.length > 1) {
      setFileLimitError(true);
      setFile(null);
    } else {
      setFileLimitError(false);
      setFile(acceptedFiles[0]);
    }
  }, []);

  const handleExcelUpload = () => {
    try {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log("file reading error:", err);
        } else {
          let rows = resp.rows;
          if (JSON.stringify(rows[0]) === JSON.stringify(EXCEL_HEADERS)) {
            rows.shift();
            const diffSections = validateSections(rows);
            const index = diffSections.indexOf("");
            if (index > -1) {
              diffSections.splice(index, 1);
            }
            if (diffSections.length > 0) {
              setDiffSections(diffSections);
              MessageAlert(
                "error",
                "Unidentified sections found",
                JSON.stringify(diffSections),
                "Refer the valid the sections"
              );
              return false;
            } else {
              setIsUploaded(true);
              let allItems = orderGuide.line_items;
              let newItems = preProcessExcelData(rows);
              if (orderGuide?.have_og) {
                newItems.map(item => {
                  return allItems.push(item);
                });
              } else {
                allItems = newItems;
              }
              MessageAlert(
                "success",
                "Successfull",
                `${file.name} uploaded successfully`,
                ""
              );
              updateOrderGuide({
                line_items: allItems,
                status: ogStatus.crunching
              });
            }
          } else {
            setExcelHeadersInvalid(true);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const preProcessExcelData = lineItems => {
    const lineItemNames = orderGuide?.line_item_names;
    let processedLineItems = [];

    lineItems.map(item => {
      let sampleItems = {};
      lineItemNames.map((field, index) => {
        return (sampleItems[field] =
          typeof item[index] === "undefined" ? "" : item[index]);
      });
      sampleItems["verified"] = false;
      processedLineItems.push(sampleItems);
      return processedLineItems;
    });
    return processedLineItems;
  };

  const getUploadButton = () => {
    if (!isUploaded && file) {
      return (
        <Form.Group>
          <Button variant="link" size="sm">
            {file.name}
          </Button>
          <Form.Row className="justify-content-end">
            <Button
              variant="primary"
              size="sm"
              onClick={e => {
                e.preventDefault();
                // handleExcelUpload()
                AreYouSureAlert(
                  handleExcelUpload,
                  "Are you sure?",
                  "You won't be able to revert this!",
                  "Yes,Upload",
                  {
                    type: "success",
                    message: `${file.name} uploaded successfully`
                  },
                  {
                    type: "error",
                    title: "Unidentified sections found",
                    text: JSON.stringify(diffSections),
                    footer: "Refer the valid the sections"
                  }
                );
              }}
            >
              Upload
            </Button>
          </Form.Row>
        </Form.Group>
      );
    }
  };

  return (
    <Container>
      <Row className={css(styles.fileDownloadSection)}>
        <Col sm={12} className="mt-2">
          <h5>Files uploaded on {orderGuide.created_at}</h5>
          <ol>
            {orderGuide.image_urls.map((excel, index) => (
              <li key={index}>
                <Button
                  variant="link"
                  size="sm"
                  onClick={e => {
                    e.preventDefault();
                    window.open(excel);
                  }}
                  style={{
                    wordWrap: "break-word"
                  }}
                >
                  {excel.includes(FILE_SAVED_DOMAIN_1) && (
                    <span>{excel.replace(FILE_SAVED_DOMAIN_1, "")}</span>
                  )}
                  {excel.includes(FILE_SAVED_DOMAIN_2) && (
                    <span>{excel.replace(FILE_SAVED_DOMAIN_2, "")}</span>
                  )}
                </Button>
              </li>
            ))}
          </ol>
        </Col>
        <Col sm={12} className="justify-content-start">
          <Button
            variant="outline-dark"
            size="sm"
            onClick={e => {
              e.preventDefault();
              multiDownload(orderGuide?.image_urls);
            }}
          >
            Download All
          </Button>
        </Col>
      </Row>
      <Row className={css(styles.fileUploadSection)}>
        <Col sm={12} className="mt-2">
          <h5>Upload completed XLS to add items</h5>
          {isClaimed && (
            <Form.Group>
              <Alert variant="warning">Claim order guide to upload.</Alert>
            </Form.Group>
          )}
          <Form.Group controlId="formUploadFiles" className="mt-4">
            <DropZone
              onDrop={onDrop}
              acceptableTypes="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              placeholder="Select excel"
              isDisabled={isClaimed}
              isMultiple={false}
            />
          </Form.Group>
          <Form.Group>
            {fileLimitError && (
              <Alert variant="danger">
                Upload limit exceeded,single file can be uploaded at once.
              </Alert>
            )}
          </Form.Group>
          {getUploadButton()}
          {excelHeadersInvalid && (
            <Form.Group>
              <Alert variant="danger">
                Invalid Headers Found,Please refer the sample order guide file
              </Alert>
            </Form.Group>
          )}
          {isUploaded && (
            <Form.Group>
              <Alert variant="success">{file.name} uploaded successfully</Alert>
            </Form.Group>
          )}
          {diffSections.length > 0 && (
            <Form.Group>
              <Alert variant="danger">
                Upload failed due to {diffSections.length} unidentified{" "}
                {diffSections.length > 1 ? "sections" : "section"}
                <Button
                  variant="danger"
                  size="sm"
                  className="mx-2"
                  onClick={e => {
                    e.preventDefault();
                    MessageAlert(
                      "info",
                      "Unidentified Sections",
                      JSON.stringify(diffSections),
                      "Refer valid sections"
                    );
                  }}
                >
                  View
                </Button>
              </Alert>
            </Form.Group>
          )}
        </Col>
      </Row>
    </Container>
  );
}
const styles = StyleSheet.create({
  fileDownloadSection: {
    border: "1px solid black",
    maxHeight: "45vh",
    minHeight: "45vh",
    padding: "5px",
    width: "100%"
  },
  fileUploadSection: {
    border: "1px solid black",
    maxHeight: "45vh",
    minHeight: "45vh",
    width: "100%"
  }
});

ExcelUpload.propTypes = {
  orderGuide: PropTypes.object.isRequired,
  isClaimed: PropTypes.bool.isRequired,
  updateOrderGuide: PropTypes.func.isRequired
};

export default ExcelUpload;
