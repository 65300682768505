import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "components/login/Login";

import AppContainer from "components/layout/AppContainer";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route>
          <AppContainer />
        </Route>
      </Switch>
    </Router>
  );
}
export default App;
