import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ogPriorityTypes } from "lib/ui/enums/enums";
import { put } from "networking/http";
import { UPDATE_ORDER_GUIDE_BY_ID, UPDATE_INVOICE } from "config/config";
import { SwalAlert, MessageAlert } from "lib/ui/alert";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { SwalToast } from "lib/ui/toast";

function PriorityDropdown({ selected, itemId, type }) {
  let priorities = Object.keys(ogPriorityTypes);
  let variants = ["danger", "warning", "info", "secondary"];

  let selectedVariant = variants[priorities.indexOf(selected)];

  const [priority, setPriority] = useState(selected);
  const [variant, setVariant] = useState(selectedVariant);

  useEffect(() => {
    setPriority(selected);
    setVariant(selectedVariant);
  }, [selected, selectedVariant]);

  const handleSelected = async(eventKey) => {
    let priority = priorities[eventKey];

    setPriority(priority);
    setVariant(variants[eventKey]);

    try {
      let url = "";
      if (type === "invoice") {
        url = UPDATE_INVOICE;
      } else {
        url = UPDATE_ORDER_GUIDE_BY_ID;
      }
      await put(`${url}${itemId}`, {
        priority: priority,
      })
        .then((res) => {
          if (res.data) {
            SwalToast("success", "Priority updated successfully", "top-end");
          }
        })
        .catch((error) => {
          MessageAlert("error", error.message, "Priority update failed", "try again...");
        });
    } catch (err) {
      SwalAlert("error", err.message);
    }
  };

  return (
    <DropdownButton
      id="dropdown-item-button"
      size="sm"
      variant={variant}
      title={priority}
      onSelect={(eventKey, event) => handleSelected(eventKey)}
    >
      {priorities.map((priority, i) => (
        <Dropdown.Item key={i} eventKey={i}>
          {priority}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

PriorityDropdown.propTypes = {
  selected: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default PriorityDropdown;
