import React from 'react';
import PropTypes from 'prop-types';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'aphrodite';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';

import ActionButtonsView from 'components/invoices/crunch/ActionButtonsView';
import STATUS_OPTIONS from 'lib/const/status';
import cursor from 'lib/css/cursor';
import fonts from 'lib/css/fonts';
import { statusStyleMapper } from 'lib/helpers/dashboard';

function OrderDetailView({
  activeTab,
  invoice,
  claimable,
  updateFieldsHandler,
  claimHandler,
  invoiceUpdateHandler,
}) {
  return (
    <div>
      <Row className="mx-2 my-4">
        <Col md={4}>
          <h1>{invoice?.vendor_name}</h1>
        </Col>
        <Col md={3} className="flex-column">
          <h3>
            Order ID :
            <span className={classNames(css(fonts.digitalNumbers))}>{invoice?.reference_id}</span>
          </h3>
          <h5>
            Status:
            <span className={classNames(statusStyleMapper[invoice.status], 'mx-2 text-capitalize')}>
              {STATUS_OPTIONS[invoice.status].label}
            </span>
          </h5>
          {invoice.vendor_helper_image && (
            <p className="text-muted">
              Helper Image
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                className={classNames(css(cursor.pointer), 'mx-2')}
                onClick={() => window.open(invoice.vendor_helper_image)}
              />
            </p>
          )}
        </Col>
        {invoice.status !== STATUS_OPTIONS.published.value && (
          <Col className="justify-content-end">
            <ActionButtonsView
              claimable={claimable}
              invoiceID={invoice?._id}
              status={invoice?.status}
              crunchedBy={invoice?.crunched_by}
              activeTab={activeTab}
              claimHandler={claimHandler}
              updateFieldsHandler={updateFieldsHandler}
              invoiceUpdateHandler={invoiceUpdateHandler}
            />
          </Col>
        )}
      </Row>
      <Row className="border border-secondary border-left-0 border-right-0 mx-2 my-4 px-2 py-2">
        <Col>
          <strong>Crunched By: </strong>
          {invoice.crunched_by || '-'}
        </Col>
        <Col>
          <strong>Published By: </strong>
          {(invoice.status === STATUS_OPTIONS.published.value && invoice.reviewed_by) || '-'}
        </Col>
        {invoice.problem_by && (
          <Col>
            <strong>Problem By: </strong>
            {invoice.problem_by || '-'}
          </Col>
        )}
        <Col>
          <strong>Field Updated By: </strong>
          {invoice.field_update_by || '-'}
        </Col>
      </Row>
    </div>
  );
}

OrderDetailView.propTypes = {
  activeTab: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  claimable: PropTypes.bool.isRequired,
  updateFieldsHandler: PropTypes.func.isRequired,
  claimHandler: PropTypes.func.isRequired,
  invoiceUpdateHandler: PropTypes.func.isRequired,
};

export default OrderDetailView;
