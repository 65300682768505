import React, { useContext, useEffect, useState } from 'react';

import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { useHistory, useParams } from 'react-router-dom';

import {
  GET_INVOICES_BY_ID,
  UPDATE_INVOICE,
  GET_VERIFIED_ITEMS_PER_VENDOR_INVOICE,
  GET_INVOICE_MAP,
  FETCH_BRANDS,
} from 'config/config';
import Items from 'components/invoices/crunch/Items';
import Details from 'components/invoices/crunch/Details';
import OrderDetailView from 'components/invoices/crunch/OrderDetailView';
import ProblemDescription from 'components/invoices/crunch/ProblemDescription';
import { AppNavBarContext } from 'components/layout/AppNavBar.jsx';
import UserContext from 'components/layout/UserContext';
import ImageCarousel from 'components/shared/ImageCarousel';
import { useFormFields } from 'lib/hooks/useFormFields';
import { useInvoiceFieldsUpdate } from 'lib/hooks/useInvoiceFieldsUpdate';
import { MessageAlert, SwalAlert } from 'lib/ui/alert';
import { ogStatus, ogStatusUI } from 'lib/ui/enums/enums';
import { SwalToast } from 'lib/ui/toast';
import { get, put } from 'networking/http';

function Crunch() {
  const [, setAppNavBarConfig] = useContext(AppNavBarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState();
  const [isClaimable, setIsClaimable] = useState(false);
  const [startTime, setStartTime] = useState(); // time tracking - start time for invoice
  const [details, setDetails, initializeDetails] = useFormFields();
  const [lineItems, setLineItems] = useState([]);
  const [verifiedItems, setVerifiedItems] = useState({});
  const [productCodes, setProductCodes] = useState([]);
  const [subtotal, setSubtotal] = useState('');
  const [mapping, setMapping] = useState({});
  const [brands, setBrands] = useState([]);
  const user = useContext(UserContext);
  const [tabKey, setTabKey] = useState('details');
  const updateInvoiceFields = useInvoiceFieldsUpdate();
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    setAppNavBarConfig({
      action: 'back',
      title: 'Invoice Crunch Dashboard',
      path: '/invoice/dashboard',
    });
  }, [setAppNavBarConfig]);

  useEffect(() => {
    setStartTime(new Date());
    const id = params.id;
    getInvoice(id);
    getInvoiceMap();
    getBrands();
  }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (invoice && user) {
      if (
        (invoice.status === ogStatus.pending ||
          invoice.status === ogStatus.crunching ||
          invoice.status === ogStatus.crunched) &&
        invoice?.crunched_by !== user.ocr_user?.name
      ) {
        setIsClaimable(true);
      }
    }
  }, [invoice, user]);

  const getInvoice = id => {
    setIsLoading(true);
    get(`${GET_INVOICES_BY_ID}${id}`)
      .then(res => {
        setIsLoading(false);
        setStartTime(new Date());
        const data = res.data?.invoice;
        if (data) {
          setInvoice(data);
          setSubtotal(data?.subtotal);
          getVerifiedItems(data?.vendor_id);
          initializeDetails(
            Object.keys(data.header_items).length > 0
              ? data?.header_items
              : data?.header_item_names.reduce(
                  (o, key) => ({
                    ...o,
                    [key]: '',
                  }),
                  {},
                ),
          );
          setLineItems(
            data?.line_items.length > 0
              ? data.line_items
              : [
                  ...lineItems,
                  data?.line_item_names.reduce(
                    (o, key) => ({
                      ...o,
                      [key]: '',
                    }),
                    {},
                  ),
                ],
          );
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
        MessageAlert(
          'error',
          err.message,
          'Failed to reterive invoice,click to go back to dashbaord',
          'Try again...',
        );
      });
  };

  const getVerifiedItems = vendorID => {
    get(`${GET_VERIFIED_ITEMS_PER_VENDOR_INVOICE}${vendorID}`).then(res => {
      if (res.data) {
        const invoiceItems = res.data.verified_invoice_items;
        setVerifiedItems(invoiceItems);
        setProductCodes(
          Object.keys(invoiceItems).map(item => {
            return invoiceItems[item]['product_code_number'];
          }),
        );
      }
    });
  };

  const getInvoiceMap = () => {
    get(GET_INVOICE_MAP).then(res => {
      if (res.data) {
        setMapping(res.data);
      }
    });
  };

  const getBrands = () => {
    get(FETCH_BRANDS).then(res => {
      if (res.data) {
        setBrands(res.data.brands);
      }
    });
  };

  const handleClaim = status => {
    setIsLoading(true);
    setIsClaimable(false);

    let data = {};

    if (status === ogStatus.crunched) {
      data['status'] = ogStatus.reviewing;
      data['reviewed_by'] = user.ocr_user?.name;
    } else {
      data['status'] = ogStatus.crunching;
      data['crunched_by'] = user.ocr_user?.name;
    }

    put(`${UPDATE_INVOICE}${invoice._id}`, data).then(res => {
      //start timer when og status change on clicking on claim
      setIsLoading(false);
      if (res.data) {
        setInvoice(res.data.invoice);
        setStartTime(new Date());
        SwalToast('success', `Status updated to ${ogStatusUI[data.status]}`);
      }
    });
  };

  function updateInvoice(status, data) {
    // make the subtotal and invoice number values mandatory
    if (status === ogStatus.crunched &&
      (!subtotal || (!data?.header_items?.invoice_number && !invoice.header_items?.invoice_number))
    ) {
      SwalAlert(
        "error",
        `Please Enter ${subtotal ? 'Invoice Number' : 'Sub Total'}`);
    }
    else {
      put(`${UPDATE_INVOICE}${invoice._id}`, data)
        .then(res => {
          setInvoice(res.data.invoice);
          SwalToast('success', 'Updated invoice Successfully', 'top-end');
          if (status === ogStatus.published) {
            history.push('/invoice/dashboard');
          }
        })
        .catch(err => {
          MessageAlert('error', err.message, 'Failed to update invoice', 'Try again...');
        });
      }
  }

  const handleSubmit = (type, status) => {
    let endTime = new Date();
    let totalTime = (endTime - startTime) / 1000; //diff given in mili seconds

    let data = {};
    if (type === 'details') {
      data['header_items'] = details;
      data['subtotal'] = subtotal;
      setTabKey('items');
    } else if (type === 'items') {
      data['line_items'] = lineItems;
    }

    if (status === ogStatus.published) {
      if (Number(invoice?.review_time) > 0) {
        totalTime = Number(invoice?.review_time) + Number(totalTime);
      }
      data['status'] = status;
      data['review_time'] = totalTime;
      data['reviewed_by'] = user.ocr_user?.name;
      data['is_archived'] = true;
    } else if (status === ogStatus.recheck) {
      if (Number(invoice?.review_time) > 0) {
        totalTime = Number(invoice?.review_time) + Number(totalTime);
      }
      data['review_time'] = totalTime;
      data['status'] = ogStatus.crunching;
      data['reviewed_by'] = user.ocr_user?.name;
    }
    //in reviewing stage if user clicked on submit/crunch do not change the state to 'crunching'
    else if (
      (invoice?.status === ogStatus.reviewing ||
        (invoice?.status === ogStatus.crunched && invoice?.crunched_by !== user?.ocr_user.name)) &&
      status === ogStatus.crunching
    ) {
      data['status'] = ogStatus.reviewing;
      data['reviewed_by'] = user.ocr_user?.name;
    } else {
      if (Number(invoice?.crunch_time) > 0) {
        totalTime = Number(invoice?.crunch_time) + Number(totalTime);
      }
      data['status'] = status;
      data['crunch_time'] = totalTime;
      data['crunched_by'] = user.ocr_user?.name;
    }
    updateInvoice(status, data);
  };

  const handleUpdateFields = () => {
    setIsLoading(true);
    updateInvoiceFields(invoice._id, invoice.vendor_id, getInvoice);
    setIsLoading(false);
  };

  if (!invoice) {
    return null;
  }
  return (
    <LoadingOverlay>
      {!invoice ? null : (
        <Container fluid>
          <OrderDetailView
            activeTab={tabKey}
            invoice={invoice}
            claimable={isClaimable}
            updateFieldsHandler={handleUpdateFields}
            claimHandler={handleClaim}
            invoiceUpdateHandler={handleSubmit}
          />
          <div>
            <Row className={css(styles.crunchRow)}>
              <Col md="4">
                <ImageCarousel imageList={invoice?.image_url} />
              </Col>
              <Col md="8">
                {invoice?.status === ogStatus.problem ? (
                  <ProblemDescription invoice={invoice} />
                ) : (
                  <Tabs activeKey={tabKey} onSelect={k => setTabKey(k)}>
                    <Tab eventKey="details" title="DETAILS" className="border">
                      {details && (
                        <Details
                          activeTab={tabKey}
                          invoice={invoice}
                          details={details}
                          setDetails={setDetails}
                          isClaimed={isClaimable}
                          subtotal={subtotal}
                          setSubtotal={setSubtotal}
                          user={user}
                          updateInvoiceHandler={handleSubmit}
                        />
                      )}
                    </Tab>
                    <Tab
                      eventKey="items"
                      title="ITEMS"
                      className="border"
                      disabled={invoice?.status === ogStatus.pending}
                    >
                      <Items
                        activeTab={tabKey}
                        invoice={invoice}
                        lineItems={lineItems}
                        setLineItems={setLineItems}
                        isClaimed={isClaimable}
                        verifiedItems={verifiedItems}
                        productCodes={productCodes}
                        subtotal={subtotal}
                        mapping={mapping}
                        user={user}
                        updateInvoiceHandler={handleSubmit}
                        brands={brands}
                      />
                    </Tab>
                  </Tabs>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      )}

      <Loader loading={isLoading} text={'Loading,Please wait...'} />
    </LoadingOverlay>
  );
}
const styles = StyleSheet.create({
  crunchRow: {
    maxHeight: '90vh',
    minHeight: '90vh',
  },
});
export default Crunch;
