import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "containers/App";
import * as Sentry from "@sentry/browser";

import "bootstrap/dist/css/bootstrap.min.css";

const isDev = serviceWorker.isLocalhost ? true : false;

if (!isDev) {
  Sentry.init({
    dsn: "https://3666b2851ebb4afdbfa9765da8b95ef6@sentry.io/1887691",
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
