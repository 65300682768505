import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";
import Autosuggest from "react-autosuggest";

import { keyCodes } from "lib/ui/enums/enums";
import "asset/index.css";

const types = {
  type: "type",
  click: "click",
  down: "down",
  up: "up",
};

function CustomAutoSuggest({
  options,
  fuzzySuggestions = [],
  defaultValue,
  styles,
  onCreate,
  onUpdate,
  keyPressOption,
  isClaimEnable,
  isBorderEnable,
}) {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [action, setAction] = useState();
  const [viewFuzzySuggestions, setViewFuzzySuggestions] = useState(true);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue("");
    }
  }, [defaultValue]);

  useEffect(() => {
    if (fuzzySuggestions.length && viewFuzzySuggestions) {
      setSuggestions(fuzzySuggestions);
    }
  }, [fuzzySuggestions, viewFuzzySuggestions]);

  const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const getSuggestions = (value) => {
    if (viewFuzzySuggestions) {
      return fuzzySuggestions;
    }
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }
    const regex = new RegExp("^" + escapedValue, "i");
    return options.filter((option) => regex.test(option));
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion;
  };

  const renderSuggestion = (suggestion) => {
    return <span className={viewFuzzySuggestions ? 'text-danger' : ''}>{suggestion}</span>;
  };

  const onChange = (event, { newValue, method }) => {
    setViewFuzzySuggestions(false); 
    setAction(method);
    setValue(newValue);
  };

  const onKeyPressOption = (e) => {
    if (e.keyCode === keyCodes.enter && e.ctrlKey) {
      keyPressOption(e);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const saveValues = () => {
    if (action === types.type) {
      onCreate(value);
    } else if (action === types.click || action === types.down || action === types.up) {
      onUpdate(value);
    }
  };
  let inputProps = {
    placeholder: "",
    value: value,
    onBlur: saveValues,
    onChange: onChange,
    className: !isBorderEnable
      ? classNames(css(customStyles.borderBottomInput), styles)
      : `form-control ${styles}`,
    onKeyDown: onKeyPressOption,
    readOnly: isClaimEnable,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
}

const customStyles = StyleSheet.create({
  borderBottomInput: {
    border: "none",
    borderBottom: "1px solid #616A6B",
    borderRadius: "0px",
    color: "#424949 ",
    outline: "none",
    width: "100%",
  },
});

CustomAutoSuggest.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  styles: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isClaimEnable: PropTypes.bool.isRequired,
  isBorderEnable: PropTypes.bool,
};
export default CustomAutoSuggest;
