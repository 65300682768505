import Swal from "sweetalert2";

const SwalToast = (type, message, position) => {
  Swal.fire({
    position: position ? position : "top-end",
    icon: type,
    text: message,
    toast: true,
    showConfirmButton: false,
    timer: 1500
  });
};

export { SwalToast };
