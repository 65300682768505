import React from "react";
import PropTypes from "prop-types";

import { css, StyleSheet } from "aphrodite";
import { useLocation } from "react-router-dom";

function FullScreenMainContainer({ expanded, children }) {
  const location = useLocation();

  if (location.pathname.indexOf("crunch") !== -1) {
    return children;
  }

  return (
    <div className={css(expanded ? styles.container : styles.containerCollapsed)}>{children}</div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 220,
    width: "calc(100% - 220px)",
    overflowX: "hidden !important",
  },
  containerCollapsed: {
    marginLeft: 0,
    width: "100%",
    overflowX: "hidden !important",
  },
});

FullScreenMainContainer.propTypes = {
  children: PropTypes.any,
};

export default FullScreenMainContainer;
