import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';

import { Container, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { isEmpty, orderBy } from 'lodash-es';

import { AppNavBarContext } from 'components/layout/AppNavBar';
import DashboardButtonsView from 'components/invoices/dashboard/DashboardButtonsView';
import {
  FETCH_ORDER_GUIDES,
  getServer,
  UPDATE_ORDER_GUIDE,
} from 'config/config';
import { get, put } from 'networking/http';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { MessageAlert, SwalAlert } from 'lib/ui/alert';
import {
  MONTH_DAY_YEAR,
  YMD_FORMATTED,
  YEAR_MONTH_DAY_HOURS_MIN_SEC,
  YEAR_MONTH_DATE_HOURS_MIN_FORMAL,
} from 'lib/const/time';
import OrderGuideCommentsSidebar from 'components/order-guides/OrderGuideCommentsSidebar';
import OrderGuidesTableView from 'components/order-guides/OrderGuidesTableView';
import STATUS_OPTIONS from 'lib/const/status';
import SummaryGridView from 'components/shared/SummaryGridView';
import toQuerySelector from 'lib/helpers/Scroll';
import useExcelExport from 'lib/hooks/useExcelExport';
import UserContext from 'components/layout/UserContext';


const SUMMARY_KEY_LIST = [
  'total',
  STATUS_OPTIONS.pending.value,
  STATUS_OPTIONS.processing.value,
  STATUS_OPTIONS.processed.value,
  STATUS_OPTIONS.reviewing.value,
  STATUS_OPTIONS.verified.value,
  STATUS_OPTIONS.published.value,
];

const OFFSET = 60;

function OrderGuideDashboard() {
  const [isSearch, setIsSearch] = useState({initialSearch: false, searchText: ''});

  const [appNavBarConfig, setAppNavBarConfig] = useContext(AppNavBarContext);
  const scrollToID = appNavBarConfig?.state?.scrollTo;
  const user = useContext(UserContext);
  const [orderGuides, setOrderGuides] = useState();
  const [summaryData, setSummaryData] = useState({});
  const [isArchivedView, setIsArchivedView] = useState(
    appNavBarConfig.state?.isArchivedView || false);
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [comments, setComments] = useState();
  const [isLoading, setIsLoading] = useState();
  const { exportExcel } = useExcelExport();

  useEffect(() => {
    if (!isLoading) {
      if (scrollToID) {
        global.setTimeout(() => toQuerySelector(scrollToID, OFFSET));
      }
      setAppNavBarConfig({
        action: 'menu',
        title: 'Order Guide Crunch Dashboard',
        state: {isArchivedView},
      });
    }

  }, [setAppNavBarConfig, isLoading, isArchivedView, scrollToID]);


  const downloadExcel = () => {
    exportExcel(
      processedOrderGuides,
      `OrderGuides_${dayjs().format(YEAR_MONTH_DAY_HOURS_MIN_SEC)}`,
    );
  };

  /**
   *
   * fetch order guide api call memorized in order to reduce
   * unwanted api calls due to renderings.
   * callback is fired based on archived view
   * @author Hasanka Madhushan <hasanka@codify.ai>
   */

  useEffect(() => {
    if (!isArchivedView && !isSearch.initialSearch) {
      getOrderGuides();
    }
    else if (isSearch.initialSearch) {
      getOrderGuides();
    }
  }, [isSearch.initialSearch, isArchivedView]); // eslint-disable-line react-hooks/exhaustive-deps

  const getOrderGuides = useCallback(() => {
    setIsLoading(true);
    get(`${FETCH_ORDER_GUIDES}${isArchivedView}${isSearch.searchText}`)
      .then(res => {
        if (res.data) {
          setOrderGuides(res.data?.order_guides);
          setSummaryData(res.data?.status_details);
          setIsLoading(false);
          setIsSearch({...isSearch, initialSearch: false});
        }
      })
      .catch(err => {
        setIsLoading(false);
        setIsSearch({...isSearch, initialSearch: false});
        MessageAlert('error', err.message, 'Failed to load invoices', 'Please try again...');
      });
  }, [isArchivedView, isSearch]);

  /**
   * update order guide and refetch all order guides in order
   * to update the table with new changes
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {string} orderGuideID - current order guide db ref
   * @param {object} data - data object needed to updated
   */
  const updateOrderGuide = useCallback(
    (orderGuideRefID, data) => {
      put(getServer(UPDATE_ORDER_GUIDE + orderGuideRefID), data)
        .then(res => {
          if (res.data) {
            getOrderGuides();
          }
        })
        .catch(e => {
          SwalAlert('error', 'Failed to update Order guide:', e.message);
        });
    }, [getOrderGuides]
  );

  /**
   * earlier used user email for action based tracking, later moved
   * to track using username. For displaying purposes need to remove
   * email address
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {sting} username
   */
  const formatUserName = username => {
    return username
      ? username.includes('@codify.ai') || username.includes('@cutanddry.com')
        ? username.split('@')[0]
        : username
      : '-';
  };

  /**
   * total time is calculated using below logic
   * if claimed at time
   *  total time = (claimed at time - created at time) + crunch time + review time
   * else
   *  total time = crunch time + review time
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {string} crunchTime - time taken to process
   * @param {string} reviewTime - time taken to review
   * @param {string} createdAt  - order guide created time
   * @param {string} claimedAt - claim time of the order guide
   * @param {string} processedAt  - published time
   * @param {string} status - status
   * @return {sting} mm:ss
   */
  const calculateTotalTime = (crunchTime, reviewTime, createdAt, claimedAt) => {
    const processingTime = Number(crunchTime) + Number(reviewTime);
    if (claimedAt !== '') {
      return dayjs(claimedAt).diff(dayjs(createdAt), 'second') + processingTime;
    }
    return processingTime;
  };

  /**
   * Comments are sorted in descending order
   * add a explicit key read to every comment by below logic
   *  if user last comment read time is less than the current comment time and
   *    comment user is not equal to current login user
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {object} orderWiseComments  - comments for a order guide
   * @param {string} orderGuideID - order guide id
   */
  const sortComments = useCallback(
    (orderWiseComments, orderGuideID) => {
      if (user) {
        const currentCommenter = user.ocr_user.name;
        const lastReadTimes = user.ocr_user.last_read_comments_at || [];
        const sortedComments = orderBy(orderWiseComments, 'time', 'desc');
        const lastReadAt =
          orderGuideID in lastReadTimes ? lastReadTimes[orderGuideID] : '2020-01-01 00:00:00';
        sortedComments.map(comment => {
          return (comment['read'] =
            Date.parse(lastReadAt) >
              Date.parse(dayjs(comment.time).format(YEAR_MONTH_DATE_HOURS_MIN_FORMAL)) ||
            comment.user === currentCommenter);
        });
        return sortedComments;
      }
    },
    [user],
  );

  /**
   * All Order guides are manupulated and made in order to use for both order guide
   * excel support and order guide dashboard table
   * useMemo is used to memorize values based on order guides and user changes
   * @author Hasanka Madhushan <hasanka@codify.ai>
   */
  const processedOrderGuides = useMemo(() => {
    if (!isEmpty(orderGuides)) {
      return orderGuides.map(orderGuide => {
        return {
          id: orderGuide._id,
          date: dayjs(orderGuide.created_at).format(YMD_FORMATTED),
          type: orderGuide.have_og ? 'existing' : 'new',
          imgUrls: orderGuide.image_urls,
          restaurant: orderGuide.operator_name || '-',
          vendor: orderGuide.vendor_name || '-',
          vendorID: orderGuide.operator_id,
          orderID: orderGuide.order_guide_id,
          csmInfo: orderGuide.csm_info?.csm_name || '-',
          csmMeeting: orderGuide.csm_info?.csm_meeting_date
            ? dayjs(orderGuide.csm_info.csm_meeting_date).format(MONTH_DAY_YEAR)
            : '-',
          salesMeeting: dayjs(orderGuide.csm_info?.sales_meeting_date).format(MONTH_DAY_YEAR) 
            || '-',
          noOfItems: orderGuide.line_items.length,
          idleTime:
            orderGuide.claimed_at !== ''
              ? dayjs(orderGuide.claimed_at).diff(dayjs(orderGuide.created_at), 'second')
              : '0',
          crunchTime: orderGuide.crunch_time,
          reviewTime: orderGuide.review_time,
          totalTime: calculateTotalTime(
            orderGuide.crunch_time,
            orderGuide.review_time,
            orderGuide.created_at,
            orderGuide.claimed_at,
            orderGuide.processed_at,
            orderGuide.status,
          ),
          crunchBy: formatUserName(orderGuide.user_email),
          reviewedBy: formatUserName(orderGuide.reviewed_user_email),
          priority: orderGuide.priority,
          status: STATUS_OPTIONS[orderGuide.status].label,
          isArchived: orderGuide.is_archived,
          comments: sortComments(orderGuide.comments, orderGuide.order_guide_id),
        };
      });
    }
    return [];
  }, [orderGuides, sortComments]);

  /**
   * order guide dashboard table is memorized in order to reduce
   * unwanted renderings
   * @author Hasanka Madhushan <hasanka@codify.ai>
   */
  const orderGuideTableCallback = useCallback(() => {
    return (
      <OrderGuidesTableView
        data={processedOrderGuides}
        updateOrderGuide={updateOrderGuide}
        setComments={setComments}
        setCommentsExpanded={setCommentsExpanded}
      />
    );
  }, [processedOrderGuides, updateOrderGuide]);

  /**
   * adding the new comment to the first position of the comment array
   * finally updaing comments
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {string} id - order guide db ref
   * @param {string} comment  - current comment
   * @param {string} user - current commenter
   */
  const sendComments = (id, comment, user) => {
    if (comment && id && user) {
      const allComments = comments;
      allComments.comments.unshift({
        user: user,
        time: dayjs().format(YEAR_MONTH_DATE_HOURS_MIN_FORMAL),
        comment: comment,
        read: true,
      });
      updateOrderGuide(id, { comments: allComments.comments });
    }
  };

  if (!processedOrderGuides || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container fluid className="px-5 py-4">
      {commentsExpanded && (
        <OrderGuideCommentsSidebar
          expanded={commentsExpanded}
          setExpanded={setCommentsExpanded}
          comments={comments}
          sendComments={sendComments}
        />
      )}
      <DashboardButtonsView
          isArchived={isArchivedView}
          setIsArchived={setIsArchivedView}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          downloadExcel={downloadExcel}
          isOrderGuideView={true}
        />
      <SummaryGridView
        summaryData={summaryData}
        summaryKeyList={SUMMARY_KEY_LIST}
      />
      <Row className="my-4">{orderGuideTableCallback()}</Row>
    </Container>
  );
}

export default OrderGuideDashboard;
