import React, { useEffect, useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import InvoiceCrunch from 'components/invoices/crunch/Crunch';
import InvoiceDashboard from 'components/invoices/dashboard/Dashboard';
import LandingPageView from 'components/LandingPageView';
import AppNavBar, { AppNavBarContext } from 'components/layout/AppNavBar';
import FullScreenMainContainer from 'components/layout/FullScreenMainContainer';
import UserContext from 'components/layout/UserContext';
import OrderGuidesCrunch from 'components/order-guides/OGCrunch';
import OrderGuideDashboard from 'components/order-guides/OrderGuideDashboard';
import { useUser } from 'lib/hooks/useUser';

function AppContainer() {
  const [appNavBarConfig, setAppNavBarConfig] = useState({});
  const [menuBarVisible, setMenuBarVisible] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const currentUser = useUser();

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.login) {
        setRedirectToLogin(true);
      }
    }
  }, [currentUser]);

  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }
  return (
    <UserContext.Provider value={currentUser}>
      <AppNavBarContext.Provider value={[appNavBarConfig, setAppNavBarConfig]}>
        <Router>
          <div className={css(styles.pageWrapper)}>
            <div className={css(styles.main)}>
              <AppNavBar
                expanded={menuBarVisible}
                setExpanded={setMenuBarVisible}
                {...appNavBarConfig}
              />
              <FullScreenMainContainer expanded={menuBarVisible}>
                <Switch>
                  <Route path="/invoice/dashboard">
                    <InvoiceDashboard />
                  </Route>
                  <Route path="/invoice/crunch/:id">
                    <InvoiceCrunch />
                  </Route>
                  <Route path="/order-guide/dashboard">
                    <OrderGuideDashboard />
                  </Route>
                  <Route path="/order-guide/crunch/:id">
                    <OrderGuidesCrunch />
                  </Route>
                  <Route>
                    <LandingPageView />
                  </Route>
                </Switch>
              </FullScreenMainContainer>
            </div>
          </div>
        </Router>
      </AppNavBarContext.Provider>
    </UserContext.Provider>
  );
}

const styles = StyleSheet.create({
  main: {
    transition: 'margin .5s',
    width: '100%',
  },
  pageWrapper: {
    height: '100%',
    overflowX: 'none',
    width: '100%',
  },
});

export default AppContainer;
