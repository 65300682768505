import { getBlob } from 'networking/http';

export function useDownload() {
	return function(url, name) {
		(async() => {
			await getBlob(url, { mode: 'cors', cache: 'no-cache' }).then(
				(response) => {
					response.arrayBuffer().then(function(buffer) {
						const urlDup = window.URL.createObjectURL(new Blob([buffer]));
						const link = document.createElement('a');
						link.href = urlDup;
						link.setAttribute('download', name); //or any other extension
						document.body.appendChild(link);
						link.click();
					});
				}
			);
		})();
	};
}
