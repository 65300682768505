const PENDING = "pending";
const CRUNCHING = "processing";
const CRUNCHED = "processed";
const REVIEWING = "reviewing";
const REVIEWED = "verified";
const ABORTED = "aborted";
const PUBLISHED = "published";
const PROBLEM = "problem";
const RECHECK = "recheck";

const pending = "pending";
const crunching = "crunching";
const crunched = "crunched";
const reviewing = "reviewing";
const reviewed = "reviewed";
const aborted = "aborted";
const published = "published";
const problem = "problem";
const recheck = "recheck";

const STATUS_OPTIONS = {
  [PENDING]: { label: pending, value: PENDING },
  [CRUNCHING]: { label: crunching, value: CRUNCHING },
  [CRUNCHED]: { label: crunched, value: CRUNCHED },
  [REVIEWING]: { label: reviewing, value: REVIEWING },
  [REVIEWED]: { label: reviewed, value: REVIEWED },
  [ABORTED]: { label: aborted, value: ABORTED },
  [PUBLISHED]: { label: published, value: PUBLISHED },
  [PROBLEM]: { label: problem, value: PROBLEM },
  [RECHECK]: { label: recheck, value: RECHECK },
};

export default STATUS_OPTIONS;
