import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { INPUT_VALID_PATTERN } from 'lib/ui/enums/enums';
import STATUS_OPTIONS from 'lib/const/status';

const formatString = label => {
  return label.replace(/_/g, ' ').replace('/', '');
};

const SAVE_BTN_HIDDEN_STATUS = [
  STATUS_OPTIONS.pending.value,
  STATUS_OPTIONS.published.value,
  STATUS_OPTIONS.problem.value,
  STATUS_OPTIONS.processed.value,
];

function Details({
  activeTab,
  invoice,
  details,
  setDetails,
  isClaimed,
  subtotal,
  setSubtotal,
  user,
  updateInvoiceHandler,
}) {
  const isSaveBtnVisible = useMemo(() => {
    if (user) {
      return (
        !SAVE_BTN_HIDDEN_STATUS.includes(invoice.status) &&
        invoice.crunched_by === user.ocr_user.name &&
        !isClaimed
      );
    }
  }, [invoice, user, isClaimed]);

  const getInputPattern = name => {
    if (name.includes('zip')) {
      return INPUT_VALID_PATTERN.ANY_CHARACTER_PATTERN;
    } else if (name.includes('total')) {
      return INPUT_VALID_PATTERN.FLOAT_SEVEN_DECIMALS_PATTERN;
    } else {
      return INPUT_VALID_PATTERN.ANY_CHARACTER_PATTERN;
    }
  };

  return (
    <div className={css(styles.tableContainer)}>
      <Form autoComplete="off" >
        {invoice?.header_item_names &&
          invoice?.header_item_names.map(item => (
            <Form.Group as={Row} controlId={item} key={item} className="text-capitalize">
              <Form.Label column sm="4" className={css(styles.detailsLabels)}>
                {formatString(item)}
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type={item.includes('date') ? 'date' : 'search'}
                  pattern={getInputPattern(item)}
                  onChange={setDetails}
                  className={css(styles.detailsInput)}
                  plaintext={isClaimed}
                  readOnly={isClaimed}
                  value={details[item] || ''}
                />
              </Col>
            </Form.Group>
          ))}
        <Form.Group as={Row} controlId="subtotal">
          <Form.Label column sm="4" className={css(styles.detailsLabels)}>
            Subtotal
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern={INPUT_VALID_PATTERN.FLOAT_SEVEN_DECIMALS_PATTERN}
              onChange={e => {
                setSubtotal(e.target.validity.valid ? e.target.value : subtotal);
              }}
              className={css(styles.detailsInput)}
              plaintext={isClaimed}
              readOnly={isClaimed}
              value={subtotal}
            />
          </Col>
        </Form.Group>
        {isSaveBtnVisible && (
          <Form.Group as={Row}>
            <Col sm={{ span: 3, offset: 4 }}>
              <Button
                block
                className="font-weight-bold"
                onClick={() => {
                  updateInvoiceHandler(activeTab, STATUS_OPTIONS.processing.value);
                }}
              >
                Save
              </Button>
            </Col>
          </Form.Group>
        )}
      </Form>
    </div>
  );
}
const styles = StyleSheet.create({
  detailsInput: {
    border: 'none',
    borderBottom: '1px solid #616A6B',
    borderRadius: '0px',
    color: '#424949 ',
    outlineStyle: 'none',
  },
  detailsLabels: {
    color: 'black',
    fontStyle: 'capitalize',
  },
  tableContainer: {
    minHeight: '79vh',
    maxHeight: '79vh',
    margin: '25px 0px 25px 50px',
    overflowX: 'hidden !important',
    overflowY: 'auto !important',
  },
  saveBtn: {
    width: '25%',
  },
});
Details.propTypes = {
  activeTab: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
  isClaimed: PropTypes.bool.isRequired,
  subtotal: PropTypes.string,
  setSubtotal: PropTypes.func.isRequired,
  user: PropTypes.object,
  updateInvoiceHandler: PropTypes.func.isRequired,
};

export default Details;
export { SAVE_BTN_HIDDEN_STATUS };
