const ogStatus = {
	pending: 'pending',
	crunching: 'processing',
	crunched: 'processed',
	reviewing: 'reviewing',
	reviewed: 'verified',
	aborted: 'aborted',
	published: 'published',
	problem: 'problem',
	recheck: 'recheck',
};

const ogStatusUI = {
	pending: 'Pending',
	processing: 'Crunching',
	processed: 'Crunched',
	reviewing: 'Reviewing',
	verified: 'Reviewed',
	aborted: 'Aborted',
	published: 'Published',
	problem: 'Problem',
};

const ogPriorityTypes = {
	P1: 'danger',
	P2: 'warning',
	P3: 'info',
	P4: 'secondary',
};

const ogArchiveTypes = {
	Show: 'Archive',
	Hide: 'Unarchive',
};

const keyCodes = {
	enter: 13,
};

const INPUT_VALID_PATTERN = {
	FLOAT_SEVEN_DECIMALS_PATTERN: '^-?\\d*(\\.\\d{0,7})?$',
	INTEGERS_PATTERN: '[0-9]*',
	ANY_CHARACTER_PATTERN: '.*',
};

const INPUT_TYPES = {
	TEXT: 'text',
	DATE: 'date',
	NUMBER: 'number',
};

const ERROR_CODES_INVOICE = {
	multiple_invoices_same_vendor: 'Multiple invoices for the same vendor',
	image_not_an_invoice: 'Image not an invoice',
	blurry_image: 'Blurry image',
	helper_does_not_match: 'Helper image does not match',
	wrong_vendor: 'Wrong vendor',
	multiple_vendors: 'Multiple vendors',
	error_loading_image: 'Error loading image',
	other: 'Other',
};

const ERROR_CODES_ORDER_GUIDE = {
	image_not_an_order_guide: 'Image not an order guide',
	blurry_image: 'Blurry image',
};

export {
	ogStatus,
	ogStatusUI,
	ogPriorityTypes,
	ogArchiveTypes,
	keyCodes,
	INPUT_VALID_PATTERN,
	INPUT_TYPES,
	ERROR_CODES_INVOICE,
	ERROR_CODES_ORDER_GUIDE,
};
