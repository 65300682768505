import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const FILE_TYPE =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILE_EXTENSTION = '.xlsx';

const exportToXlsx = (csvData = [], fileName = 'file') => {
	const ws = XLSX.utils.json_to_sheet(csvData);
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: FILE_TYPE });
	FileSaver.saveAs(data, fileName + FILE_EXTENSTION);
};
export default exportToXlsx;
