import React from "react";
import { useDropzone } from "react-dropzone";
import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";

function Dropzone({ onDrop, acceptableTypes, placeholder, isDisabled,isMultiple }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptableTypes,
    onDrop,
    disabled: isDisabled,
    multiple: isMultiple,
  });
  return (
    <div {...getRootProps()} className={css(styles.dropzoneBox)}>
      <input {...getInputProps()} />
      <span className={css(styles.dropzoneText)}>
          {placeholder ? placeholder : "Select images"}
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  dropzoneBox: {
    background: "white",
    border: "2px dashed #1AB394",
    cursor: "pointer",
    minHeight: "100px",
    padding: "20px 20px"
  },
  dropzoneText: {
    margin: "15px 0"
  }
});

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  acceptableTypes: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  isMultiple: PropTypes.bool.isRequired,

};

export default Dropzone;
