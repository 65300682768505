import React, { useContext, useEffect } from "react";

import { css } from "aphrodite";
import { Card, CardDeck, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { AppNavBarContext } from "components/layout/AppNavBar.jsx";
import cursor from "lib/css/cursor";

function LandingPageView() {
  const [, setAppNavBarConfig] = useContext(AppNavBarContext);

  useEffect(() => {
    setAppNavBarConfig({
      action: "menu",
      title: "CRUNCH",
    });
  }, [setAppNavBarConfig]);

  return (
    <Container className="px-5 my-5">
      <Row>
        <CardDeck>
          <LinkContainer to="/invoice/dashboard" className={css(cursor.pointer)}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="border-bottom border-dark">
                  Invoice Crunch Dashboard
                </Card.Title>
                <Card.Text>
                  Go directly to Invoice Dashboard to view all archived/unarchived invoices
                </Card.Text>
              </Card.Body>
            </Card>
          </LinkContainer>
          <LinkContainer to="/order-guide/dashboard" className={css(cursor.pointer)}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="border-bottom border-dark">
                  Order Guide Crunch Dashboard
                </Card.Title>
                <Card.Text>
                  Go directly to Order Guide Dashboard to view all archived/unarchived order guides
                </Card.Text>
              </Card.Body>
            </Card>
          </LinkContainer>
        </CardDeck>
      </Row>
    </Container>
  );
}

export default LandingPageView;
