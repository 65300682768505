import React from 'react';
import PropTypes from 'prop-types';

import { HEADERS } from 'components/order-guides/OrderGuidesTableView.js';

function TableRowPlaceholder({ id, height }) {
  return (
    <tr className="placeholder" id={'order_guide_' + id} style={{height: height}}>
      {
        HEADERS.map((header, index) => 
          <td key={header + index}></td>
        )}
    </tr>
  );
}

TableRowPlaceholder.propTypes = {
  id: PropTypes.string,
  height: PropTypes.number,
};

export default TableRowPlaceholder;
