import { isLocalhost } from 'serviceWorker';

let SERVER = 'https://digitization-api.cutanddry.com/api/v1/ocr';
let GET_AUTH_URL = 'https://digitization-api.cutanddry.com/api/v1/auth/redirect';

if (isLocalhost) {
  SERVER = 'http://localhost:5000/api/v1/ocr';
  GET_AUTH_URL = `http://localhost:5000/api/v1/auth/redirect`;
}

const GET_INVOICES = `${SERVER}/get_invoices?is_archived=`;
const UPDATE_INVOICE = `${SERVER}/update_invoice?invoice_id=`;
const GET_INVOICES_BY_ID = SERVER + '/get_invoice?invoice_id=';
const GET_VERIFIED_ITEMS_PER_VENDOR_INVOICE = `${SERVER}/get_verified_items?vendor_id=`;
const GET_INVOICE_MAP = `${SERVER}/get_invoice_map`;
const GET_CURRENT_USER = `${SERVER}/get_current_user`;

const AWS_ACCESS_KEY = 'AKIAQ2WAOVLZABMZW7H5';
const AWS_SECRET_KEY = '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';
const AWS_S3_BUCKET = 'orderguidesocr';
const AWS_REGION = 'us-east-2';

const ADD_ORDERGUIDE = `${SERVER}/add_order_guide`;
const GET_ORDER_GUIDES = `${SERVER}/get_order_guides`;
const GET_ORDER_GUIDES_BY_ID = `${SERVER}/get_order_guide?output_order_guide_id=`;
const GET_ORDER_GUIDE_UOMS = `${SERVER}/get_uoms`;
const GET_ORDER_GUIDE_SECTIONS = `${SERVER}/get_sections`;
const GET_VERIFIED_ITEMS_BY_VENDOR_ID_OG = `${SERVER}/get_verified_items?
order_guide=true&vendor_id=`;
const UPDATE_ORDER_GUIDE_BY_ID = `${SERVER}/update_order_guide?output_order_guide_id=`;
const GET_VERIFIED_VENDORS = `${SERVER}/get_verified_vendors`;
const GET_INVOICE_FIELDS = `${SERVER}/get_mapped_invoice_fields?vendor_id=`;

const UPDATE_LAST_COMMENTS_READ_AT = `${SERVER}/update_user`;

// rewriting urls
const FETCH_ORDER_GUIDES = `${SERVER}/get_order_guides?is_archived=`;
const UPDATE_ORDER_GUIDE = '/update_order_guide?output_order_guide_id=';

// Summary related api calls
const FETCH_INVOICE_SUMMARY_COUNT = '/get_invoices_summary?is_archived=';
const FETCH_ORDER_GUIDE_SUMMARY_COUNT = '/get_order_guides_summary?is_archived=';

const FETCH_BRANDS = `${SERVER}/get_brands`;

const getServer = url => {
  return `${SERVER}${url}`;
};

export {
  SERVER,
  GET_INVOICES,
  UPDATE_INVOICE,
  GET_INVOICES_BY_ID,
  GET_VERIFIED_ITEMS_PER_VENDOR_INVOICE,
  GET_CURRENT_USER,
  GET_AUTH_URL,
  AWS_ACCESS_KEY,
  AWS_SECRET_KEY,
  AWS_S3_BUCKET,
  AWS_REGION,
  ADD_ORDERGUIDE,
  GET_ORDER_GUIDES,
  GET_ORDER_GUIDES_BY_ID,
  GET_ORDER_GUIDE_UOMS,
  GET_ORDER_GUIDE_SECTIONS,
  GET_VERIFIED_ITEMS_BY_VENDOR_ID_OG,
  UPDATE_ORDER_GUIDE_BY_ID,
  GET_VERIFIED_VENDORS,
  GET_INVOICE_MAP,
  GET_INVOICE_FIELDS,
  getServer,
  UPDATE_LAST_COMMENTS_READ_AT,
  FETCH_ORDER_GUIDES,
  UPDATE_ORDER_GUIDE,
  FETCH_INVOICE_SUMMARY_COUNT,
  FETCH_ORDER_GUIDE_SUMMARY_COUNT,
  FETCH_BRANDS,
};
