import React from 'react';
import propTypes from 'prop-types';
import { Card, Form, Row } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';
import { ERROR_CODES_INVOICE } from 'lib/ui/enums/enums';

function ProblemDescription({ invoice }) {
	return (
		<Card border='dark' className={css(styles.problemDescription)}>
			<Card.Header as='h5'>Problem Reported</Card.Header>
			<Card.Body>
				<Form>
					<Form.Group as={Row}>
						<Form.Label column sm='2' className='font-weight-bold'>
							Error
						</Form.Label>
						<Form.Label column sm='5'>
							{ERROR_CODES_INVOICE[invoice?.error]}
						</Form.Label>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm='2' className='font-weight-bold'>
							Comment
						</Form.Label>
						<Form.Label column sm='10'>
							{invoice?.comment}
						</Form.Label>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm='2' className='font-weight-bold'>
							Reporter
						</Form.Label>
						<Form.Label column sm='5'>
							{invoice?.problem_by}
						</Form.Label>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm='2' className='font-weight-bold'>
							Reported Date
						</Form.Label>
						<Form.Label column sm='5'>
							{invoice?.processed_at}
						</Form.Label>
					</Form.Group>
				</Form>
			</Card.Body>
		</Card>
	);
}

const styles = StyleSheet.create({
	problemDescription: {
		width: '100%',
		height: 'auto',
	},
});

ProblemDescription.propTypes = {
	invoice: propTypes.object.isRequired,
};

export default ProblemDescription;
