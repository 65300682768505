import React, { useContext, useEffect, useState } from "react";

import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useParams } from "react-router-dom";

import { AppNavBarContext } from "components/layout/AppNavBar.jsx";
import fonts from "lib/css/fonts";
import ExcelUpload from "components/order-guides/ExcelUpload";
import { get, put } from "networking/http";
import { GET_ORDER_GUIDES_BY_ID, UPDATE_ORDER_GUIDE_BY_ID } from "config/config";
import ImageCarousel from "components/shared/ImageCarousel";
import OGCrunchDataGrid from "components/order-guides/OGDataGrid";
import { ogStatus, ogStatusUI } from "lib/ui/enums/enums";
import ReportErrorModal from 'components/invoices/crunch/ReportErrorModal';
import { statusStyleMapper } from "lib/helpers/dashboard";
import { SwalToast } from "lib/ui/toast";
import { toTop } from 'lib/helpers/Scroll';
import UserContext from "components/layout/UserContext";
import useModal from 'lib/hooks/useModal';


function OGCrunch() {
  const [appNavBarConfig, setAppNavBarConfig] = useContext(AppNavBarContext);
  const ArchivedView = appNavBarConfig?.state?.isArchivedView;
  const [isLoading, setIsLoading] = useState(false);
  const [orderGuide, setOrderGuide] = useState();
  const [startTime, setStartTime] = useState(); // time tracking - start time for og
  const [isClaimEnable, setIsClaimEnable] = useState(false);
  const [fileTypes, setFileTypes] = useState([]);
  const currentUser = useContext(UserContext);
  const params = useParams();
  const { isShowing, toggle } = useModal();


  useEffect(() => {
    setAppNavBarConfig({
      action: "back",
      title: "Order Guide Crunch Dashboard",
      path: "/order-guide/dashboard",
      state: { isArchivedView: ArchivedView, scrollTo: '#order_guide_' + params.id },
    });
    toTop();
  }, [setAppNavBarConfig, params.id, ArchivedView]);

  useEffect(() => {
    setStartTime(new Date());
    if (currentUser) {
      const id = params.id;
      get(`${GET_ORDER_GUIDES_BY_ID}${id}`).then((res) => {
        if (res) {
          setOrderGuide(res.data?.output_order_guide);
          getImageUrlTypes(res.data?.output_order_guide?.image_urls);
          if (
            (res.data?.output_order_guide?.status === ogStatus.pending ||
              res.data?.output_order_guide?.status === ogStatus.crunching) &&
            res.data?.output_order_guide?.user_email !== currentUser.ocr_user?.name
          ) {
            setIsClaimEnable(true);
          } else {
            if (res.data?.output_order_guide?.status === ogStatus.crunched) {
              put(`${UPDATE_ORDER_GUIDE_BY_ID}${id}`, {
                status: ogStatus.reviewing,
              }).then((res) => {
                SwalToast("success", "Status updated to reviewing", "top-end");
              });
            }
          }
          setIsLoading(false);
        }
      });
    }
  }, [params, currentUser]);

  const handleClaim = () => {
    setIsLoading(true);
    setIsClaimEnable(false);

    put(`${UPDATE_ORDER_GUIDE_BY_ID}${orderGuide._id}`, {
      status: "processing",
      user_email: currentUser?.ocr_user?.name,
    }).then((res) => {
      //start timer when og status change on clicking on claim
      setOrderGuide(res.data.output_order_guide);
      setStartTime(new Date());
      setIsLoading(false);
      SwalToast("success", "Successfully claimed and status updated to crunching");
    });
  };

  const getImageUrlTypes = (imageUrls) => {
    let invoiceFileTypes = [];
    if (imageUrls.length > 0) {
      imageUrls.map((image) => {
        return invoiceFileTypes.push(image.substr(image.lastIndexOf(".") + 1));
      });
      setFileTypes(invoiceFileTypes);
    }
  };

  const updateOrderGuide = (data) => {
    put(`${UPDATE_ORDER_GUIDE_BY_ID}${orderGuide._id}`, data)
      .then((res) => {
        setOrderGuide(res.data?.output_order_guide);
        SwalToast("success", "Successfully updated order guide", "top-end");
      })
      .catch((err) => {
        SwalToast("error", "Failed updating order guide", "top-end");
      });
  };

  return (
    <LoadingOverlay>
      <Container fluid>
        <Row className="mx-2 my-4">
          <Col>
            <h1>{orderGuide?.operator_name}</h1>
            <h5 className="text-muted">
              {orderGuide?.vendor_name}
              {orderGuide?.have_og && (
                <Badge variant="success" className="mx-2">
                  EXISTING
                </Badge>
              )}
              <Badge variant="secondary" className="mx-2 text-uppercase">
                {orderGuide?.order_guide_type}
              </Badge>
            </h5>
          </Col>
          <Col>
            <h3>
              Order Guide :{" "}
              <span className={css(fonts.digitalNumbers)}>{orderGuide?.order_guide_id}</span>
            </h3>
            <h5>
              Status:
              <span className={classNames(statusStyleMapper[orderGuide?.status], "mx-2")}>
                {ogStatusUI[orderGuide?.status]}
              </span>
            </h5>
          </Col>
          <Col>
            <div className="d-flex flex-row-reverse">
              {isClaimEnable && (
                <div class="p-2">
                  <Button
                    variant="primary"
                    size="sm"
                    className="mx-2 font-weight-bold"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClaim();
                    }}
                  >
                    Claim
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={2} className="my-1">
            <Button block variant="danger" className="font-weight-bold" onClick={toggle}>
              Report a Problem
            </Button>
            {isShowing && (
              <ReportErrorModal
                hide={toggle}
                invoiceID={orderGuide?._id}
                user={currentUser?.name}
                isInvoice={false}
              />
            )}
          </Col>
        </Row>
        <Row className={css(styles.crunchRow)}>
          <Col md="4">
            {fileTypes.includes("xlsx") ? (
              <ExcelUpload
                orderGuide={orderGuide}
                isClaimed={isClaimEnable}
                updateOrderGuide={updateOrderGuide}
              />
            ) : (
              <div>{orderGuide && <ImageCarousel imageList={orderGuide?.image_urls} />}</div>
            )}
          </Col>
          <Col sm="8">
            {orderGuide && (
              <OGCrunchDataGrid
                orderGuide={orderGuide}
                startTime={startTime}
                isClaimEnable={isClaimEnable}
                setOrderGuide={setOrderGuide}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Loader loading={isLoading} text={"Loading,Please wait..."} fullPage />
    </LoadingOverlay>
  );
}

const styles = StyleSheet.create({
  crunchRow: {
    maxHeight: "90vh",
    minHeight: "90vh",
  },
});

export default OGCrunch;
