import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { css } from 'aphrodite';
import classNames from 'classnames';
import { Alert, Button, Col, Row } from 'react-bootstrap';

import ReportErrorModal from 'components/invoices/crunch/ReportErrorModal';
import UserContext from 'components/layout/UserContext';
import STATUS_OPTIONS from 'lib/const/status';
import { backgroundColorStyles } from 'lib/css/colors';
import useModal from 'lib/hooks/useModal';

const ALERT_HIDDEN_STATUS = [
  STATUS_OPTIONS.problem.value,
  STATUS_OPTIONS.processing.value,
  STATUS_OPTIONS.pending.value,
];
const RECHECK_ENABLED_STATUS = [STATUS_OPTIONS.reviewing.value, STATUS_OPTIONS.processed.value];
const CRUNCH_TEXT_ENABLED_STATUS = [STATUS_OPTIONS.pending.value, STATUS_OPTIONS.processing.value];
function ActionButtonsView({
  claimable,
  invoiceID,
  status,
  crunchedBy,
  activeTab,
  claimHandler,
  updateFieldsHandler,
  invoiceUpdateHandler,
}) {
  const { isShowing, toggle } = useModal();
  const user = useContext(UserContext);

  const isActionBtnVisible = useMemo(() => {
    return status !== STATUS_OPTIONS.problem.value && !claimable;
  }, [status, claimable]);

  const isPublishBtnVisible = useMemo(() => {
    return (
      status !== STATUS_OPTIONS.processed.value &&
      status !== STATUS_OPTIONS.recheck.value &&
      status === STATUS_OPTIONS.reviewing.value &&
      crunchedBy !== user?.ocr_user.name
    );
  }, [status, crunchedBy, user]);

  const isAlertVisible = useMemo(() => {
    if (user) {
      return crunchedBy === user?.ocr_user.name && !ALERT_HIDDEN_STATUS.includes(status);
    }
  }, [status, crunchedBy, user]);

  const isRecheckBtnVisible = useMemo(() => {
    if (user) {
      return crunchedBy !== user?.ocr_user.name && RECHECK_ENABLED_STATUS.includes(status);
    }
  }, [status, user, crunchedBy]);

  const isCrunchedBtnVisible = useMemo(() => {
    return status !== STATUS_OPTIONS.reviewing.value;
  }, [status]);

  return (
    <div>
      {isActionBtnVisible && (
        <Row className="justify-content-end">
          <Col md={4} className="my-1">
            <Button
              block
              variant="secondary"
              className="font-weight-bold"
              onClick={e => updateFieldsHandler()}
            >
              Update Fields
            </Button>
          </Col>
          <Col md={4} className="my-1">
            <Button block variant="danger" className="font-weight-bold" onClick={toggle}>
              Report a Problem
            </Button>
            {isShowing && (
              <ReportErrorModal
                hide={toggle}
                invoiceID={invoiceID}
                user={user.ocr_user?.name}
                invoice={true}
              />
            )}
          </Col>
        </Row>
      )}
      {claimable && (
        <Row className="justify-content-end">
          <Col md={3}>
            <Button
              block
              variant="primary"
              className="font-weight-bold"
              onClick={() => {
                claimHandler(status);
              }}
            >
              {CRUNCH_TEXT_ENABLED_STATUS.includes(status) ? 'Crunch' : 'Review'}
            </Button>
          </Col>
        </Row>
      )}
      {isActionBtnVisible && (
        <Row className="my-2">
          {isPublishBtnVisible && (
            <Col className="my-1">
              <Button
                block
                className={classNames(
                  css(backgroundColorStyles.salmon),
                  'border-0 font-weight-bold',
                )}
                onClick={() => invoiceUpdateHandler(activeTab, STATUS_OPTIONS.published.value)}
              >
                Publish
              </Button>
            </Col>
          )}
          {isCrunchedBtnVisible && (
            <Col className="my-1">
              <Button
                block
                variant="success"
                className="font-weight-bold"
                onClick={() => invoiceUpdateHandler(activeTab, STATUS_OPTIONS.processed.value)}
              >
                Crunched
              </Button>
            </Col>
          )}

          {isRecheckBtnVisible && (
            <Col className="my-1">
              <Button
                block
                variant="warning"
                className="font-weight-bold"
                onClick={() => invoiceUpdateHandler(activeTab, STATUS_OPTIONS.recheck.value)}
              >
                Recheck
              </Button>
            </Col>
          )}

          <Col className="my-1">
            <Button
              block
              className="font-weight-bold"
              onClick={() => invoiceUpdateHandler(activeTab, STATUS_OPTIONS.processing.value)}
            >
              Save
            </Button>
          </Col>
        </Row>
      )}
      {isAlertVisible && (
        <Row className="my-1">
          <Col>
            <Alert variant="danger">
              You cannot <strong>Publish</strong> your own crunched Invoice.
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
}

ActionButtonsView.propTypes = {
  claimable: PropTypes.bool.isRequired,
  invoiceID: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  crunchedBy: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  claimHandler: PropTypes.func.isRequired,
  updateFieldsHandler: PropTypes.func.isRequired,
  invoiceUpdateHandler: PropTypes.func.isRequired,
};

export default ActionButtonsView;
