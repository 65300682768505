import { useState, useEffect } from "react";
import { GET_CURRENT_USER } from "config/config";
import { useGet } from "./useGet";

const useUser = () => {
  const [loginDetails, loginError] = useGet(GET_CURRENT_USER);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (loginDetails) {
      setUser(loginDetails);
    } else if (loginError) {
      console.error(loginError);
    }
  }, [loginDetails, loginError]);

  return user;
};
export { useUser };
