import React, { useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import Select from "react-select";
import ToggleSwitch from 'react-switch';

import "react-datepicker/dist/react-datepicker.css";

const SEARCH_OPTIONS = [
  {label: "Order ID", value: "reference_id"},
  {label: "Restaurant", value: "header_items.ship_to_restaurant_name"},
  {label: "Vendor", value: "vendor_name"},
  {label: "Status", value: "status"},
];

const OG_SEARCH_OPTIONS = [
  {label: "Order Guide ID", value: "order_guide_id"},
  {label: "Restaurant", value: "operator_name"},
  {label: "Vendor", value: "order_guide_name"},
  {label: "Status", value: "status"},
];

function DashboardButtonsView({ 
  isArchived, 
  setIsArchived, 
  isSearch, 
  setIsSearch, 
  downloadExcel, 
  isOrderGuideView = false,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [searchOption, setSearchOption] = useState("");
  const [searchText, setSearchtext] = useState("");

  return (
    <Row className="justify-content-end my-4">
      <Col md={2}>
        <Select
          options={isOrderGuideView ? OG_SEARCH_OPTIONS : SEARCH_OPTIONS}
          value={searchOption}
          placeholder={'Select a search criteria'}
          onChange={e => setSearchOption(e)}
        />
      </Col>
      <Col md={2}>
        <Form.Control
          className={css(styles.inputText)}
          type="text"
          value={searchText || ""}
          placeholder={"Enter a search value"}
          onChange={e => setSearchtext(e.target.value)}
        />
      </Col>
      <Col md={2}>
        <Form.Control
            className={css(styles.inputText)}
            type="text"
            value={endDate 
              ? dayjs(startDate).format('YYYY-MM-DD') + " - " + dayjs(endDate).format('YYYY-MM-DD') 
              : ''
            }
            placeholder={"Select A Date"}
            disabled={true}
            onChange={e => setSearchtext(e.target.value)}
        />
      </Col>
      <DatePicker
        data-tip={"Select A Date Range"}
        dateFormat="yyyy/MM/dd"
        selected={startDate}
        onChange={dates => {
          const [start, end] = dates;
          setStartDate(start);
          setEndDate(end);
        }}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={
          <span data-tip={"Select A Date Range"}>
            <FontAwesomeIcon icon={faCalendarAlt} className="ml-3 fa-2x" />
          </span>
        }
        shouldCloseOnSelect={false}
      />
      <ReactTooltip type="success" effect="solid" place="bottom"/>

      <Col md={2} className="d-flex justify-content-start my-1 ml-4">
        <h4 className="mr-2">Archived view:</h4>
        <ToggleSwitch
          onChange={() => {
            setIsArchived(!isArchived);
          }}
          checked={isArchived}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </Col>
      <Col md={1}>
        <Button
          onClick={() => {
            let param = searchOption 
              ? "&" + searchOption.value + "=" + encodeURIComponent(searchText.trim()) 
              : "";
            param = param.concat(endDate 
              ? "&startDate=" + dayjs(startDate).format('YYYY-MM-DD') + 
              "&endDate=" + dayjs(endDate).format('YYYY-MM-DD') 
              : "");
            setIsSearch({...isSearch, searchText: param, initialSearch: true});
          }}
        >
          {'Search'}
        </Button>
      </Col>
      <Col md={1} className="d-flex justify-content-end">
        <Button size="md" className="font-weight-bold" onClick={() => downloadExcel()}>
          <FontAwesomeIcon icon={faFileExcel} className="10x" data-tip={"Download Excel"}/>
        </Button>
        <ReactTooltip type="success" effect="solid" place="bottom"/>
      </Col>
    </Row>
  );
}

const styles = StyleSheet.create({
  inputText: {
    height: '38px',
    fontSize: '16px',
  },
});

export default DashboardButtonsView;
