import React from "react";

import { StyleSheet, css } from "aphrodite";
import classNames from "classnames";
import Spinner from "react-bootstrap/Spinner";

function LoadingSpinner() {
  return (
    <div
      className={classNames(
        css(styles.container),
        "w-100 d-flex align-items-center justify-content-center",
      )}
    >
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100vh",
  },
});

export default LoadingSpinner;
