import { uniq } from "lodash-es";

function useOrderGuideFileType() {
  const getFileType = (urls) => {
    if (urls.length > 0) {
      const types = uniq(urls.map((url) => url.substr(url.lastIndexOf(".") + 1)));

      if ((types.includes("jpg") || types.includes("png")) && types.includes("xlsx")) {
        return "both";
      } else if (types.includes("jpg") || types.includes("png")) {
        return "image";
      } else if (types.includes("xlsx")) {
        return "doc";
      }
    }
  };
  return { getFileType };
}

export default useOrderGuideFileType;
