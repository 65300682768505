import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Badge, Dropdown, DropdownButton, Image, Nav, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faComment, faFileAlt, faFileExcel, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter } from 'lodash-es';
import LazyLoad from 'react-lazyload';
import { LinkContainer } from 'react-router-bootstrap';
import Switch from 'react-switch';

import { badgesBackground, green, steel } from 'lib/css/colors';
import cursor from 'lib/css/cursor';
import cutAndDryLogoURL from 'lib/images/logos/cutanddry.svg';
import PRIORITY_OPTIONS from 'lib/const/priority';
import TableRowPlaceholder from 'components/order-guides/dashboard/TableRowPlaceholder';
import useOrderGuideFileType from 'lib/hooks/useOrderGuideFileType';
import { WarningAlert } from 'lib/ui/alert';

const HEADERS = [
  '#',
  'Date',
  'Type',
  'Format',
  'Restaurant',
  'Vendor',
  'Order Guide ID',
  'CSM',
  'CSM Meeting',
  'Sales Meeting',
  'Item Count',
  'Idle Time (mm:ss)',
  'Crunch Time (mm:ss)',
  'Review Time (mm:ss)',
  'Total Time (mm:ss)',
  'Crunched By',
  'Reviewed By ',
  'Priority',
  'Status',
  'Archive',
  'Actions',
];

const ROW_HEIGHT = 70;
const TABLE_OFFSET = 200;

function OrderGuidesTableView({ data, updateOrderGuide, setComments, setCommentsExpanded }) {
  const { getFileType } = useOrderGuideFileType();

  /**
   * if order guide is existing will display cut and dry logo
   * other wise display file icon
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {string} type - existing or new
   */
  const getOrderGuideType = type => {
    return type === 'exisiting' ? (
      <Image src={cutAndDryLogoURL} width="20"></Image>
    ) : (
      <FontAwesomeIcon className="fa-2x" icon={faFileAlt} color={steel} />
    );
  };

  /**
   * identifying image urls type equals to image or doc or both
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {Array} urls  - array of image urls for order guide
   */
  const getType = urls => {
    const urlType = getFileType(urls);
    if (urlType === 'image') {
      return <FontAwesomeIcon className="fa-2x" icon={faFileImage} color={steel} />;
    } else if (urlType === 'doc') {
      return <FontAwesomeIcon className="fa-2x" icon={faFileExcel} color={green} />;
    } else if (urlType === 'both') {
      return (
        <div className="d-flex flex-row">
          <FontAwesomeIcon className="fa-2x" icon={faFileImage} color={steel} />
          <FontAwesomeIcon className="mx-2 fa-2x" icon={faFileExcel} color={green} />
        </div>
      );
    }
  };

  /**
   * formating seconds to mm:ss format
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {string} seconds -  time in seconds
   */
  const formatTime = seconds => {
    return parseInt(seconds / 60) + ':' + Math.abs(Math.round(seconds % 60));
  };

  /**
   * Displaying current priority in drop down and providing functionality to
   * update the priority
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {string} orderRefID - order guide db ref id
   * @param {string} priority - assigned priority
   */
  const priorityDropDown = (orderRefID, priority) => {
    return (
      <DropdownButton
        size="sm"
        variant={PRIORITY_OPTIONS[priority]}
        title={priority}
        id="priority-dropdown"
        onSelect={eventKey => updateOrderGuide(orderRefID, { priority: eventKey })}
      >
        {Object.keys(PRIORITY_OPTIONS).map((priorityType, i) => (
          <Dropdown.Item key={i} eventKey={priorityType}>
            {priorityType}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };

  /**
   * handles order guide archiving and unarchiving functionality
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {string} orderRefID - order guide db ref id
   * @param {string} orderGuideID - order guide id
   * @param {*} isArchived - current archived status
   */
  const archiveHandler = (orderRefID, orderGuideID, isArchived) => {
    const onArchive = () => updateOrderGuide(orderRefID, { is_archived: !isArchived });
    !isArchived
      ? WarningAlert(
          onArchive,
          'Archived!',
          `Order Guide ${orderGuideID} has been archived.`,
          'Archive',
          `Are you sure you want archive Order Guide ${orderGuideID}?`,
        )
      : WarningAlert(
          onArchive,
          'Unarchived!',
          `Order guide has been unarchived.`,
          'Unarchive',
          `Are you sure you want unarchive Order Guide ${orderGuideID}?`,
        );
  };

  /**
   * returns the count of unread comments for a order guide for the
   * current logged in user
   * @author Hasanka Madhushan <hasanka@codify.ai>
   * @param {Array} comments - comments for a order guide
   */
  const newCommentsCount = comments => {
    return filter(comments, ['read', false]).length;
  };

  function DoubleScroll(element) {
    const body_element = document.styleSheets[0];
    if (body_element) {
      body_element.insertRule('::-webkit-scrollbar {height:8px}');
      body_element.insertRule(
        '::-webkit-scrollbar-thumb {border-radius: 4px;' +
          'background-color: rgba(0, 0, 0, .5)}');
    }

    let tableWidth = document.getElementById('mainTable').getBoundingClientRect().width;

    var scrollbar = document.createElement('div');
    scrollbar.appendChild(document.createElement('div'));
    scrollbar.style.overflow = 'auto';
    scrollbar.style.overflowY = 'hidden';
    scrollbar.firstChild.style.width = tableWidth + TABLE_OFFSET + 'px';
    scrollbar.firstChild.style.paddingTop = '10px';
    scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
    scrollbar.onscroll = function() {
        element.scrollLeft = scrollbar.scrollLeft;
    };
    element.onscroll = function() {
        scrollbar.scrollLeft = element.scrollLeft;
    };
    element.parentNode.insertBefore(scrollbar, element);
  }
  useEffect(() => {
    const elem = document.getElementById('doublescroll');
    if (elem) {
      DoubleScroll(document.getElementById('doublescroll'));
    }
  }, []);

  return (
    <>
    <div id="doublescroll" className={css(styles.doublescroll)}>
      <Table id="mainTable" className={css(styles.table)} bordered striped hover>
        <thead>
          <tr>
              {HEADERS.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
      <tbody>
        {
        data.map((orderGuide, index) => (
            <LazyLoad
              key={index}
              height={ROW_HEIGHT}
              offset={100}
              once={true}
              placeholder={<TableRowPlaceholder id={orderGuide.id} height={ROW_HEIGHT}/>}
            >
              <tr key={orderGuide.id} id={'order_guide_' + orderGuide.id}>
                <td>{index + 1}</td>
                <td>{orderGuide.date}</td>
                <td>{getOrderGuideType(orderGuide.type)}</td>
                <td>{getType(orderGuide.imgUrls)}</td>
                <td>{orderGuide.restaurant}</td>
                <td>{orderGuide.vendor}</td>
                <td>{orderGuide.orderID}</td>
                <td>{orderGuide.csmInfo}</td>
                <td>{orderGuide.csmMeeting}</td>
                <td>{orderGuide.salesMeeting}</td>
                <td>{orderGuide.noOfItems}</td>
                <td>{formatTime(orderGuide.idleTime || '0:00')}</td>
                <td>{formatTime(orderGuide.crunchTime) || '0:00'}</td>
                <td>{formatTime(orderGuide.reviewTime) || '0:00'}</td>
                <td>{formatTime(orderGuide.totalTime) || '0:00'}</td>
                <td className="text-capitalize">{orderGuide.crunchBy}</td>
                <td className="text-capitalize">{orderGuide.reviewedBy}</td>
                <td>{priorityDropDown(orderGuide.id, orderGuide.priority)}</td>
                <td>
                  <Badge
                    className={classNames(
                      css(badgesBackground[orderGuide.status]),
                      'text-capitalize text-light',
                    )}
                  >
                    {orderGuide.status}
                  </Badge>
                </td>
                <td>
                  <Switch
                    onChange={() =>
                      archiveHandler(orderGuide.id, orderGuide.orderID, orderGuide.isArchived)
                    }
                    checked={orderGuide.isArchived}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </td>
                <td>
                  <div className="d-flex">
                    <Badge
                      pill
                      variant="light"
                      className={css(cursor.pointer)}
                      onClick={() => {
                        setComments({
                          operator: orderGuide.restaurant,
                          id: orderGuide.id,
                          orderGuideID: orderGuide.orderID,
                          vendor: orderGuide.vendor,
                          comments: orderGuide.comments,
                        });
                        setCommentsExpanded(true);
                      }}
                    >
                      {newCommentsCount(orderGuide.comments)}
                      <FontAwesomeIcon
                        icon={faComment}
                        className="fa-2x mx-2"
                        color={newCommentsCount(orderGuide.comments) > 0 ? green : steel}
                      />
                    </Badge>
                    <LinkContainer to={`/order-guide/crunch/${orderGuide.id}`}>
                      <Nav.Link>View</Nav.Link>
                    </LinkContainer>
                  </div>
                </td>
              </tr>
            </LazyLoad>
          )
        )}
      </tbody>
    </Table>
    </div>
  </>
  );
}

const styles = StyleSheet.create({
  table: {
    overflowX: "scroll",
    width: '100vw'
  },
  doublescroll: {
    overflow: 'auto',
    width: '100vw',
  },
});

OrderGuidesTableView.propTypes = {
  data: PropTypes.array.isRequired,
  updateOrderGuide: PropTypes.func.isRequired,
  setComments: PropTypes.func.isRequired,
  setCommentsExpanded: PropTypes.func.isRequired,
};

export default OrderGuidesTableView;
export { HEADERS };
