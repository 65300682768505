import { StyleSheet, css } from "aphrodite";
import { SALMON } from "lib/ui/colors";

const styles = StyleSheet.create({
  badgePublish: {
    background: SALMON,
    color: "white",
  },
  badgeProblem: {
    background: "#7B7D7D",
    color: "white",
  },
  badgeReviewing: {
    background: "#283747",
    color: "white",
  },
});

const formatUserName = (username) => {
  return username
    ? username.includes("@codify.ai") || username.includes("@cutanddry.com")
      ? username.split("@")[0]
      : username
    : "-";
};

const statusStyleMapper = {
  pending: "badge badge-warning",
  processed: "badge badge-info",
  processing: "badge badge-secondary",
  reviewing: `badge ${css(styles.badgeReviewing)}`,
  verified: "badge badge-success",
  aborted: "badge badge-danger",
  published: `badge ${css(styles.badgePublish)}`,
  problem: `badge ${css(styles.badgeProblem)}`,
};

export { formatUserName, statusStyleMapper };
