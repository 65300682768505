import { useContext } from 'react';

import UserContext from 'components/layout/UserContext';
import { GET_INVOICE_FIELDS, UPDATE_INVOICE } from 'config/config';
import { ogStatus } from 'lib/ui/enums/enums';
import { MessageAlert, AreYouSureAlert } from 'lib/ui/alert';
import { get, put } from 'networking/http';

export function useInvoiceFieldsUpdate() {
	const currentUser = useContext(UserContext);

	/**
	 * get current vendor mapped fields
	 * returns object with header names and line item names along with vendor id
	 * @param {string} vendorID - vendor id for the invoice
	 */
	const getStatusWithFields = async(vendorID, invoiceID) => {
		return get(`${GET_INVOICE_FIELDS}${vendorID}&invoice_id=${invoiceID}`)
			.then((response) => {
				return response ? response.data : {};
			})
			.catch((err) => {
				MessageAlert('error', 'Failed', err.message, '');
			});
	};

	/**
	 * prepare the data to send for invoice update for
	 * new updated fields
	 * @param {object} newMappedFields
	 */
	const prepareFields = (newFields) => {
		const data = {};
		try {
			data['header_item_names'] = newFields?.header_item_names;
			data['line_item_names'] = newFields?.line_item_names;
			data['header_items'] = {};
			data['line_items'] = [];
			data['status'] = ogStatus.crunching;
			data['field_update_by'] = currentUser.ocr_user?.name;
		} catch (error) {
			console.error('Invoice Fields data preparation failed:', error.message);
		}
		return data;
	};

	/**
	 * send the update api call to update the invoice header item names and line item names
	 * @param {string} invoiceID
	 * @param {object} newFields
	 */
	const updateFields = async(invoiceID, newFields) => {
		return await put(`${UPDATE_INVOICE}${invoiceID}`, prepareFields(newFields))
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return null;
			});
	};

	/**
	 *
	 * @param {string} invoiceID
	 * @param {string} vendorID
	 * @param {Function} getInvoice
	 */
	const UpdateInvoiceFields = async(invoiceID, vendorID, getInvoice) => {
		return await getStatusWithFields(vendorID, invoiceID).then(
			(statusResponse) => {
				const status = statusResponse?.status;
				const newFields = statusResponse?.new_fields;
				if (!status) {
					MessageAlert(
						'info',
						'No Changes',
						'No changes found in the fields for this invoice.',
						'',
					);
				} else {
					const updateMappedFields = () => {
						updateFields(invoiceID, newFields).then((response) => {
							if (response.data) {
								MessageAlert(
									'success',
									'Success',
									'Fields updated successfully',
									'Details and Items will updated automatically',
								);
								getInvoice(invoiceID);
								return true;
							} else {
								MessageAlert('error', 'Failed', 'Failed to update Fields', '');
								return false;
							}
						});
					};

					AreYouSureAlert(
						updateMappedFields,
						'Changes found in the fields for this invoice. Are you sure you want to update? ',
						'Note that clicking “Yes” will reset the fields for this invoice' +
							' & any data entered will be lost.',
						'Yes',
					);
				}
			},
		);
	};

	return UpdateInvoiceFields;
}
