import { StyleSheet } from "aphrodite";

const fonts = StyleSheet.create({
  digitalNumbers: {
    fontFamily: "Orbitron",
    fontWeight: "bold",
    letterSpacing: 2,
  },
  digitalNumbersLite: {
    fontFamily: "Orbitron",
    fontSize: 12,
    letterSpacing: 1,
  },
});

export default fonts;
