import React from "react";
import LoginView from "asset/images/login.jpg";
import Swal from "sweetalert2";

import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { StyleSheet, css } from "aphrodite";
import classNames from "classnames";

import { get } from "../../networking/http";
import { GET_AUTH_URL } from "../../config/config";
import { useUser } from "lib/hooks/useUser";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function Login() {
  const history = useHistory();
  const currentUser = useUser();

  useEffect(() => {
    if (currentUser?.login) {
      history.push("/");
    }
  }, [currentUser, history]);

  const handleLogin = () => {
    get(GET_AUTH_URL)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        Swal.fire("Login Failed!", "Something went wrong!", "error");
      });
  };
  return (
    <Container className={classNames(css(styles.login), "justify-content-center")}>
      <Row>
        <Card className={classNames(css(styles.loginCard), "text-center")}>
          <Card.Img variant="top" src={LoginView} />
          <Card.Body>
            <Card.Title className="justify-content-center">CRUNCH</Card.Title>
            <Button variant="primary" size="md" onClick={handleLogin}>
              Login
            </Button>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  login: {
    alignItems: "center",
    display: "flex",
    minHeight: "100vh",
  },
  loginCard: {
    width: "25rem",
  },
});
export default Login;
