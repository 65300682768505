import React from "react";
import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

function ButtonGroupToolBar({ buttons, size, btnGroupClassName }) {
  return (
    <ButtonToolbar aria-label="Toolbar with button groups" className="">
      {buttons.map((btn, index) => (
        <ButtonGroup
          className={btnGroupClassName}
          aria-label={`${index}-group`}
          key={`${btn}-${index}`}
        >
          <Button
            variant={btn.variant}
            size={size}
            className={`${btn.className} text-capitalize`}
            disabled={btn.disabled}
            onClick={btn.onHandle}
          >
            {btn.giphy && (
              <FontAwesomeIcon
                icon={btn.giphy.icon}
                className={`${btn.giphy.className} mx-1`}
              />
            )}
            {btn.text}
          </Button>
        </ButtonGroup>
      ))}
    </ButtonToolbar>
  );
}

ButtonGroupToolBar.propTypes = {
  buttons: PropTypes.array.isRequired,
  size: PropTypes.string.isRequired
};

export default ButtonGroupToolBar;
