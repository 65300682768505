const LINE_ITEMS_SPECIAL_COLUMNS = [
  'uom',
  'item_name',
  'brand',
  'verified',
  'section',
  'existing_og',
  'is_edited',
];

const LINE_ITEM_NAMES_REMOVABLE_COLUMNS = ['is_edited', 'existing_og', 'verified', 'l1_section'];

export { LINE_ITEMS_SPECIAL_COLUMNS, LINE_ITEM_NAMES_REMOVABLE_COLUMNS };
