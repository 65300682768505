import React, { useState } from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import PropTypes from 'prop-types';

import { useDownload } from 'lib/hooks/useDownload';
import { ButtonToolbar, ButtonGroup, Button, Container } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';

import 'react-overlay-loader/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRight,
	faArrowLeft,
	faDownload,
} from '@fortawesome/free-solid-svg-icons';
import 'asset/index.css';

function ImageCarousel({ imageList }) {
	const [currentIndex, setCurrentIndex] = useState(0);
	const download = useDownload();

	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	const downloadImages = async(urls, options = {}) => {
		if (!urls) {
			throw new Error('`urls` required');
		}
		urls.map(async(url, index) => {
			const name = url.split('/').pop();
			await delay(index * 1000);
			download(url, name);
		});
	};

	return (
		<div className='border'>
			<br />
			<ButtonToolbar
				aria-label='Toolbar with button groups'
				className='justify-content-center'>
				<div className='form-inline pr-3'>
						{currentIndex + 1}/{imageList.length}
				</div>
				<ButtonGroup aria-label='navigation-group'>
					<Button
						variant='outline-primary'
						size='sm'
						onClick={(e) => {
							e.preventDefault();
							setCurrentIndex(
								currentIndex === 0 ? imageList.length - 1 : currentIndex - 1
							);
						}}>
						<FontAwesomeIcon icon={faArrowLeft} />
					</Button>
					<Button
						variant='outline-primary'
						size='sm'
						onClick={(e) => {
							e.preventDefault();
							setCurrentIndex(
								imageList.length === currentIndex + 1 ? 0 : currentIndex + 1
							);
						}}>
						<FontAwesomeIcon icon={faArrowRight} />
					</Button>
				</ButtonGroup>
			</ButtonToolbar>
			<Container className={css(styles.imageBox)}>
				<ReactPanZoom
					image={imageList[currentIndex]}
					alt='Image load failed'
					key={currentIndex}
				/>
			</Container>
			<ButtonToolbar
				aria-label='Toolbar with download button'
				className='justify-content-between my-2 mx-1'>
				<ButtonGroup aria-label='download-button'>
				<Button
						variant='outline-primary'
						size='sm'
						onClick={(e) => {
							e.preventDefault();
							downloadImages(imageList);
						}}>
						<FontAwesomeIcon icon={faDownload} className='mx-1' />
					</Button>
				</ButtonGroup>
			</ButtonToolbar>
		</div>
	);
}

const styles = StyleSheet.create({
	imageBox: {
		height: '77.8vh',
		overflow: 'auto !important',
		position: 'relative',
		width: '100%',
	},
});

ImageCarousel.propTypes = {
	imageList: PropTypes.array.isRequired,
};

export default ImageCarousel;
