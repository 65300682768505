import React, { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Badge, Button, Container, Row, Table } from 'react-bootstrap';
import Switch from 'react-switch';
import LazyLoad from 'react-lazyload';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Link, useHistory } from 'react-router-dom';
import 'react-overlay-loader/styles.css';

import DashboardButtonsView from 'components/invoices/dashboard/DashboardButtonsView';
import { AppNavBarContext } from 'components/layout/AppNavBar.jsx';
import PriorityDropdown from 'components/order-guides/dashboard/PriorityDropDown';
import SummaryGridView from 'components/shared/SummaryGridView';
import { GET_INVOICES, UPDATE_INVOICE } from 'config/config';
import { formatUserName, statusStyleMapper } from 'lib/helpers/dashboard';
import { formatSecondsTommss } from 'lib/helpers/timeFormatter';
import { MessageAlert, SwalAlert, WarningAlert } from 'lib/ui/alert';
import { ogStatusUI } from 'lib/ui/enums/enums';
import exportToXlsx from 'lib/methods/exportXlsx';
import { get, put } from 'networking/http';
import STATUS_OPTIONS from 'lib/const/status';

const TABLE_HEADERS = [
  '#',
  'Date',
  'Order ID',
  'Restaurant',
  'Vendor',
  'Item Count',
  'Crunched By',
  'Published By',
  'Crunch Time [mm:ss]',
  'Review Time [mm:ss]',
  'Total Time [mm:ss]',
  'Priority',
  'Status',
  'Archive',
  'Action',
];

const SUMMARY_KEY_LIST = [
  'total',
  STATUS_OPTIONS.pending.value,
  STATUS_OPTIONS.processing.value,
  STATUS_OPTIONS.processed.value,
  STATUS_OPTIONS.reviewing.value,
  STATUS_OPTIONS.published.value,
  STATUS_OPTIONS.problem.value,
];

const ROW_HEIGHT = 55;

function Dashboard() {
  const [, setAppNavBarConfig] = useContext(AppNavBarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [isArchived, setIsArchived] = useState(false);
  const [isSearch, setIsSearch] = useState({initialSearch: false, searchText: ''});

  const history = useHistory();

  useEffect(() => {
    setAppNavBarConfig({
      action: 'menu',
      title: 'Invoice Crunch Dashboard',
    });
  }, [setAppNavBarConfig]);

  useEffect(() => {
    if (!isArchived && !isSearch.initialSearch) {
      getInvoices();
    }
    else if (isSearch.initialSearch) {
      getInvoices();
    }
  }, [isSearch.initialSearch, isArchived]); // eslint-disable-line react-hooks/exhaustive-deps

  const getInvoices = () => {
    setIsLoading(true);
    get(`${GET_INVOICES}${isArchived}${isSearch.searchText}`)
      .then(res => {
        if (res.data) {
          setInvoices(res.data?.invoices);
          setSummaryData(res.data?.status_details);
          setIsLoading(false);
          setIsSearch({...isSearch, initialSearch: false});
        }
      })
      .catch(err => {
        setIsLoading(false);
        setIsSearch({...isSearch, initialSearch: false})
        MessageAlert('error', err.message, 'Failed to load invoices', 'Please try again...');
      });
  };

  const getHeaderWidth = header => {
    if (header.includes('[mm:ss]')) {
      return '5px';
    }
    if (header === 'archive') {
      return '20px';
    }
  };

  const handleArchive = (isArchived, id) => {
    const onArchive = async () => {
      try {
        await put(`${UPDATE_INVOICE}${id}`, {
          is_archived: !isArchived,
        }).then(res => {
          if (res.data) {
            getInvoices();
          }
        });
      } catch (err) {
        SwalAlert('error', err.message);
      }
    };

    !isArchived
      ? WarningAlert(
          onArchive,
          'Archived!',
          'Your invoice has been archived.',
          'Yes',
          'Are you sure you want archive this?',
        )
      : WarningAlert(
          onArchive,
          'Unarchived!',
          'Your invoice has been unarchived.',
          'Yes',
          'Are you sure you want unarchive this?',
        );
  };

  const downloadExcel = () => {
    if (invoices.length > 0) {
      const data = [];
      invoices.map(invoice => data.push(makeExcelData(invoice)));
      exportToXlsx(data, `Invoices_${dayjs().format('YYYY-MM-DD')}`);
    } else {
      SwalAlert('warning', 'Invoices not found');
    }
  };

  const makeExcelData = invoice => {
    return {
      date: invoice.created_at,
      orderId: invoice.reference_id,
      restaurant: invoice?.header_items?.ship_to_restaurant_name
        ? invoice?.header_items?.ship_to_restaurant_name
        : invoice?.header_items?.sold_bill_to_restaurant_name,
      vendor: invoice.vendor_name,
      crunchedBy: invoice.crunched_by || '-',
      publishedBy: invoice.reviewed_by || '-',
      problem_by: invoice.problem_by || '-',
      crunchTime: invoice.crunch_time || '0',
      reviewTime: invoice.review_time || '0',
      totalTime: Number(invoice.crunch_time) + Number(invoice.review_time),
      priority: invoice.priority,
      status: ogStatusUI[invoice.status],
    };
  };

  return (
    <LoadingOverlay>
      <Container fluid className="px-5">
        <DashboardButtonsView
          isArchived={isArchived}
          setIsArchived={setIsArchived}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          downloadExcel={downloadExcel}
        />
        <SummaryGridView
          summaryData={summaryData}
          summaryKeyList={SUMMARY_KEY_LIST}
        />
        <Row>
          <Table striped bordered hover size="md" responsive className="shadow">
            <thead>
              <tr>
                {TABLE_HEADERS.map((header, index) => (
                  <th key={index} width={getHeaderWidth(header)}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {invoices.length === 0 && (
                <tr className="text-center">
                  <td colSpan={TABLE_HEADERS.length}>No Data Found</td>
                </tr>
              )}
              {invoices &&
                invoices.map((invoice, idx) => (
                  <LazyLoad 
                    key={idx} 
                    height={ROW_HEIGHT}
                    offset={100}
                    once={true}
                  >
                    <tr key={`${invoice}-${idx}`} className="text-capitalize">
                      <td>{idx + 1}</td>
                      <td>{invoice.created_at}</td>
                      <td> 
                        { !invoice.duplicated ? 
                          invoice.reference_id :
                          <>
                          <Link to={`/invoice/crunch/${invoice.duplicated_id}`} target="_blank">
                            <Badge 
                              variant="info" 
                              className="mx-2"
                            >
                              Duplicated
                            </Badge>
                          </Link>
                            {invoice.reference_id}
                          </>}
                        </td>
                      <td>
                        {invoice.header_items?.ship_to_restaurant_name ||
                          invoice.header_items?.sold_bill_to_restaurant_name}
                      </td>
                      <td>{invoice.vendor_name}</td>
                      <td>{invoice.line_items_count}</td>
                      <td>{formatUserName(invoice.crunched_by)}</td>
                      <td>{formatUserName(invoice.reviewed_by)}</td>
                      <td>{formatSecondsTommss(invoice.crunch_time)}</td>
                      <td>{formatSecondsTommss(invoice.review_time)}</td>
                      <td>
                        {formatSecondsTommss(
                          Number(invoice.crunch_time) + Number(invoice.review_time),
                        )}
                      </td>
                      <td className="text-center">
                        <PriorityDropdown
                          selected={invoice.priority}
                          itemId={invoice._id}
                          type="invoice"
                        />
                      </td>
                      <td className="text-center">
                        <span className={statusStyleMapper[invoice.status]}>
                          {ogStatusUI[invoice.status]}
                        </span>
                      </td>
                      <td>
                        <Switch
                          onChange={() => handleArchive(invoice.is_archived, invoice._id)}
                          checked={invoice.is_archived}
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      </td>
                      <td>
                        {isArchived ? 
                          <Link to={`/invoice/crunch/${invoice._id}`} target="_blank">
                            {'View'}
                          </Link>
                          :
                          <Button 
                            variant="link"
                            onClick={e => {
                              e.preventDefault();
                              history.push(`/invoice/crunch/${invoice._id}`);
                            }}
                          >{'View'}</Button>
                        }
                      </td>
                    </tr>
                  </LazyLoad>
                ))}
            </tbody>
          </Table>
        </Row>
      </Container>
      <Loader loading={isLoading} text={'Loading,Please wait...'} fullPage />
    </LoadingOverlay>
  );
}

export default Dashboard;
