/**
 * insert a new element to a given index in an array
 * @param {number} index - inserting index
 * @param {Array} originalArray - original array that need to insert a new element
 * @param {object} insertElement - new element to insert
 */
const insertNewElement = (index, originalArray, insertElement) => {
  originalArray.splice(index, 0, insertElement);
  return originalArray;
};

/**
 * remove a element from the given array
 * @param {number} index - index to delete
 * @param {Array} originalArray - array to delete element
 */
const removeElement = (index, originalArray) => {
  originalArray.splice(index, 1);
  return originalArray;
};

/**
 * get the elements that are not available in original array
 * @param {Array} validatingArray - validating array to find non existing items
 * @param {Array} originalArray - original array to compare
 */
const getDiff = (validatingArray, originalArray) => {
  const diff = [];
  Array.from(new Set(validatingArray)).map(element => {
    if (!originalArray.includes(element)) {
      diff.push(element);
    }
    return diff;
  });
  return diff;
};

export { insertNewElement, removeElement, getDiff };
