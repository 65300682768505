import axios from 'axios';

// withCredentials => true/false, responseType => json/blob
const get = (URL, params = { withCredentials: true, responseType: 'json' }) => {
	return axios.get(URL, params);
};

const post = (URL, data) => {
	return axios.post(URL, data, { withCredentials: true });
};

const put = (URL, data) => {
	return axios.put(URL, data, { withCredentials: true });
};

const getBlob = (URL, data) => {
	return fetch(URL, data);
};

export { get, post, put, getBlob };
