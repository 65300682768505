import { StyleSheet } from 'aphrodite';

const alertSalmon = '#FFECE7';
const backgroundGrey = '#F8F9FB';
const backgroundGrey2 = '#ECF0F4';
const blue = '#507cf1';
const categoryGrey = '#F3F3F4';
const danger = '#ed5565';
const darkGrey = '#676a6c';
const extraLightSalmon = '#FFF6F4';
const fontGrey = '#292929';
const fontGrey2 = '#BFC3C7';
const green = '#1ab394';
const iconGrey = '#a7b1c2';
const lighterGrey = '#e7eaec';
const lightSalmon = '#ffdfdf';
const lightSteel = '#677e88';
const salmon = '#ff5454';
const darkerSalmon = '#EB4D4D';
const steel = '#51636b';
const white = '#ffffff';
const orange = '#FFA500';

const colorStyles = StyleSheet.create({
  blue: { color: blue },
  danger: { color: danger },
  green: { color: green },
  steel: { color: steel },
  lightergrey: { color: lighterGrey },
  salmon: { color: salmon },
});

const backgroundColorStyles = StyleSheet.create({
  blue: { backgroundColor: blue },
  backgroundGrey: { backgroundColor: backgroundGrey },
  fontGrey: { backgroundColor: fontGrey },
  iconGrey: { backgroundColor: iconGrey },
  lighterGrey: { backgroundColor: lighterGrey },
  lightSalmon: { backgroundColor: lightSalmon },
  salmon: { backgroundColor: salmon },
});

const badgesBackground = StyleSheet.create({
  pending: { backgroundColor: '#ffc107' },
  crunched: { backgroundColor: '#17a2b8' },
  crunching: { backgroundColor: '#6c757d' },
  reviewing: { backgroundColor: '#283747' },
  reviewed: { backgroundColor: '#28a745' },
  aborted: { backgroundColor: '#dc3545' },
  published: { backgroundColor: salmon },
  problem: { backgroundColor: '#7B7D7D' },
});

export {
  colorStyles,
  backgroundColorStyles,
  badgesBackground,
  alertSalmon,
  backgroundGrey,
  backgroundGrey2,
  blue,
  categoryGrey,
  danger,
  darkGrey,
  extraLightSalmon,
  fontGrey,
  fontGrey2,
  green,
  iconGrey,
  lightSalmon,
  lighterGrey,
  lightSteel,
  salmon,
  darkerSalmon,
  steel,
  white,
  orange,
};
