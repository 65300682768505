import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const FILE_EXTENSION = '.xlsx';
const FILE_TYPE =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

function useExcelExport() {
	const exportExcel = (sheet = null, fileName = null) => {
		if (!sheet && !fileName) {
			return;
		}
		const ws = XLSX.utils.json_to_sheet(sheet);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: FILE_TYPE });
		FileSaver.saveAs(data, fileName + FILE_EXTENSION);
	};

	return { exportExcel };
}

export default useExcelExport;
