import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

import UserContext from 'components/layout/UserContext';
import { UPDATE_LAST_COMMENTS_READ_AT } from 'config/config';
import { YEAR_MONTH_DATE_HOURS_MIN_FORMAL } from 'lib/const/time';
import { steel, colorStyles } from 'lib/css/colors';
import cursor from 'lib/css/cursor';
import { SwalAlert } from 'lib/ui/alert';
import { post } from 'networking/http';

function OrderGuideCommentsSidebar({ expanded, setExpanded, comments, sendComments }) {
  const [currentComment, setCurrentComment] = useState('');
  const [, setLastCommentReadAt] = useState('');
  const user = useContext(UserContext);
  const commentInputRef = useRef();

  useEffect(() => {
    if (user && comments) {
      setLastCommentReadAt(user.ocr_user.last_read_comments_at[comments?.orderGuideID] || '');
      commentInputRef.current.focus();
    }
  }, [user, comments]);

  /**
   * memorized in order to update last comment read time if user or comments changed
   */
  const updateLastCommentsReadAt = useCallback(() => {
    const allCommentsReadTimes = user.ocr_user.last_read_comments_at;
    allCommentsReadTimes[comments.orderGuideID] = dayjs().format(YEAR_MONTH_DATE_HOURS_MIN_FORMAL);

    post(UPDATE_LAST_COMMENTS_READ_AT, {
      email: user.ocr_user.email,
      last_read_comments_at: allCommentsReadTimes,
    })
      .then(res => {
        if (!res.data) {
          Swal('error', 'Failed Update last comments read time');
        }
      })
      .catch(e => {
        Swal('error', 'Something failed during Update last comments read time');
      });
  }, [user, comments]);

  const submitHandler = () => {
    if (currentComment === '') {
      SwalAlert('error', 'No comments to post');
    } else {
      sendComments(comments.id, currentComment, user.ocr_user.name);
      updateLastCommentsReadAt();
      setCurrentComment('');
      commentInputRef.current.focus();
    }
  };

  if (!user && !comments) {
    return null;
  }

  return (
    <div
      className={classNames(
        css(
          styles.container,
          !expanded && styles.containerCollapsed,
          expanded && styles.containerExpanded,
        ),
        'd-flex px-2',
      )}
    >
      <Container fluid className="text-light">
        <Row className="my-2 border-bottom">
          <Col>
            <h5>Comments</h5>
          </Col>
          <Col className="d-flex flex-row-reverse">
            <FontAwesomeIcon
              icon={faTimes}
              className={classNames(css(cursor.pointer), 'fa-2x text-light')}
              onClick={() => {
                setExpanded(false);
                updateLastCommentsReadAt();
              }}
            />
          </Col>
        </Row>
        <Row className="my-2 border-bottom">
          <Col>
            <h2> {comments?.operator}</h2>
            <h5>{comments?.vendor}</h5>
            <p>
              Order Guide:
              <span className="mx-2">{comments?.orderGuideID}</span>
            </p>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md="12">
            <Form.Control
              type="textarea"
              as="textarea"
              placeholder="Add a comment"
              value={currentComment}
              ref={commentInputRef}
              onChange={e => {
                e.preventDefault();
                setCurrentComment(e.target.value);
              }}
            />
          </Col>
          <Col className="my-2">
            <Button type="submit" variant="primary" onClick={() => submitHandler()}>
              Post
            </Button>
            <Button
              variant="light"
              className="mx-2"
              onClick={e => {
                e.preventDefault();
                setCurrentComment('');
                commentInputRef.current.focus();
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>

        {comments && (
          <Row className={css(styles.msgContainer)}>
            {comments?.comments.map((comment, index) => (
              <Col md="12" className="border-bottom" key={index}>
                <Row>
                  <Col md="12">
                    <strong className="text-capitalize">{comment.user}</strong> :{' '}
                    {dayjs(comment.time).format('YYYY-MM-DD HH:mm:ss a')}
                  </Col>
                  <Col
                    md="12"
                    className={
                      comment.read
                        ? 'my-2 text-justify'
                        : classNames(css(colorStyles.green), 'my-2 text-justify')
                    }
                  >
                    {comment.comment}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: steel,
    height: '100%',
    right: '-100vw',
    maxWidth: 350,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 1500,
  },
  containerCollapsed: {
    animationDuration: '600ms',
  },
  containerExpanded: {
    animationDuration: '600ms',
    right: 0,
  },
  msgContainer: {
    height: '60vh',
    maxHeight: '60vh',
    overflowY: 'auto !important',
  },
});

OrderGuideCommentsSidebar.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  comments: PropTypes.object,
  sendComments: PropTypes.func.isRequired,
};

export default OrderGuideCommentsSidebar;
