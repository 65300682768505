const YEAR_MONTH_DAY_HOURS_MIN_SEC = "YYYY-MM-DD HH:mm:ss a";
const YMD_FORMATTED = "MM/DD/YYYY HH:mm:ss a";
const YEAR_MONTH_DATE_HOURS_MIN_FORMAL = "YYYY-MM-DD HH:mm:ss";
const MONTH_DAY_YEAR = "MM/DD/YYYY";

export {
  YEAR_MONTH_DAY_HOURS_MIN_SEC,
  YMD_FORMATTED,
  YEAR_MONTH_DATE_HOURS_MIN_FORMAL,
  MONTH_DAY_YEAR,
};
