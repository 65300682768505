import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { ERROR_CODES_INVOICE, ERROR_CODES_ORDER_GUIDE, ogStatus } from 'lib/ui/enums/enums';
import { put } from 'networking/http';
import { SwalAlert } from 'lib/ui/alert';
import { UPDATE_INVOICE, UPDATE_ORDER_GUIDE_BY_ID } from 'config/config';
import UserContext from "components/layout/UserContext";

function ReportErrorModal({ hide, invoiceID, user, isInvoice = true}) {
	const [errorCode, setErrorCode] = useState('');
	const [comment, setComment] = useState('');
	const history = useHistory();
	const errorCodes = isInvoice ? ERROR_CODES_INVOICE : ERROR_CODES_ORDER_GUIDE;
    const currentUser = useContext(UserContext);


	const handleSubmit = (e) => {
		e.preventDefault();

		const URL = `${isInvoice ? UPDATE_INVOICE : UPDATE_ORDER_GUIDE_BY_ID}${invoiceID}`;
		put(URL, {
			status: ogStatus.problem,
			problem_by: user || currentUser.ocr_user?.name,
			error: errorCode,
			comment: comment,
			user_email: user || currentUser.ocr_user?.name,
		}).then((res) => {
			if (res.data) {
				SwalAlert('success', 'Error Reported Successfully');
				history.push(isInvoice ? '/invoice/dashboard' : '/order-guide/dashboard');
			}
		});
	};

	return (
		<Modal centered show={true} onHide={hide} animation={true}>
			<Modal.Header closeButton className='text-center'>
				<Modal.Title>Report a Problem</Modal.Title>
			</Modal.Header>
			<Modal.Body className='justify-content-center'>
				<Container>
					<Form>
						{Object.keys(errorCodes).map((error) => (
							<Form.Row className='my-2' key={error}>
								<Form.Check
									key={error}
									type='radio'
									id={`default-${error}`}
									label={errorCodes[error]}
									name={error}
									checked={errorCode === error}
									onChange={(e) => setErrorCode(e.target.name)}
								/>
							</Form.Row>
						))}
						<Form.Row>
							<Form.Group controlId='exampleForm.ControlTextarea1'>
								<Form.Label className='font-weight-bold'>Comments</Form.Label>
								<Form.Control
									as='textarea'
									rows='5'
									cols='50'
									value={comment}
									onChange={(e) => setComment(e.target.value)}
								/>
							</Form.Group>
						</Form.Row>
					</Form>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='light'
					size='sm'
					onClick={hide}
					className='border border-dark'>
					Cancel
				</Button>
				<Button
					variant='light'
					size='sm'
					className='border border-dark'
					onClick={handleSubmit}
					disabled={!errorCode}>
					Report
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

ReportErrorModal.propTypes = {
	hide: PropTypes.func.isRequired,
	invoiceID: PropTypes.string.isRequired,
	user: PropTypes.string.isRequired,
	isInvoice: PropTypes.bool,
};

export default ReportErrorModal;
