import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";
import { Button, Navbar } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import AppNavSideBar from "components/layout/AppSideNavBar";
import { steel } from "lib/css/colors";

const AppNavBarContext = React.createContext(null);

function AppNavBar({ expanded, setExpanded, action = "menu", title = "CRUNCH" }) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setExpanded(false);
  }, [location, setExpanded]);

  const brandClickHandler = useCallback(
    (action) => {
      if (action === "back") {
        history.goBack();
      } else if (action === "menu") {
        setExpanded(!expanded);
      }
    },
    [expanded, setExpanded, history],
  );

  return (
    <React.Fragment>
      <Navbar
        sticky="top"
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className={classNames(
          css(expanded ? styles.navbarExpanded : styles.navbarCollapsed),
          "border-bottom",
        )}
      >
        <Navbar.Brand>
          <Button className={css(styles.navBrandButton)} onClick={() => brandClickHandler(action)}>
            {action === "back" && <FontAwesomeIcon className="text-white" icon={faChevronLeft} />}
            {action === "menu" && <FontAwesomeIcon className="text-white" icon={faBars} />}
          </Button>
          {!expanded && <span className={classNames(css(styles.title), "mx-3")}>{title}</span>}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <FontAwesomeIcon className="mx-2" icon={faSignOutAlt} />
            Sign out
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      {action === "menu" && <AppNavSideBar expanded={expanded} setExpanded={setExpanded} />}
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  navBrandButton: {
    background: steel,
  },
  navbarCollapsed: {
    color: "#008CBA",
    minHeight: 50,
    paddingTop: "max(0.5rem, env(safe-area-inset-top))",
    zIndex: 1040,
  },
  navbarExpanded: {
    marginLeft: 220,
    width: "calc(100% - 220px)",
    color: "#008CBA",
    minHeight: 50,
    paddingTop: "max(0.5rem, env(safe-area-inset-top))",
    zIndex: 1040,
  },
  title: {
    fontSize: 25,
    fontWeight: "bolder",
    lineHeight: 1,
  },
});

AppNavBar.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  action: PropTypes.oneOf(["back", "menu"]),
  children: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  path: PropTypes.string,
};

export default AppNavBar;
export { AppNavBarContext };
