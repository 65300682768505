import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CutAndDryLogo from "asset/images/cutanddry.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Badge,
  ButtonToolbar,
  ButtonGroup,
  Image
} from "react-bootstrap";

function OGLineItemSummary({ lineItems, isExistingOG }) {
  const [allItemsCount, setAllItemsCount] = useState(lineItems.length);
  const [existingCount, setExistingCount] = useState(0);
  const [existingEditedCount, setExistingEditedCount] = useState(0);
  const [newCount, setNewCount] = useState(0);

  useEffect(() => {
    setAllItemsCount(lineItems.length);
    setExistingCount(
      lineItems.filter(item => {
        return item.existing_og && !item.is_edited;
      }).length
    );
    setExistingEditedCount(
      lineItems.filter(item => {
        return item.existing_og && item.is_edited;
      }).length
    );
    setNewCount(
      lineItems.filter(item => {
        return !item.existing_og;
      }).length
    );
  }, [lineItems, isExistingOG]);

  return (
    <ButtonToolbar aria-label="Toolbar with button groups" className="my-2">
      <ButtonGroup className="mr-2" aria-label="Fourth group">
        <Button variant="outline-info" size="sm">
          Total Items
          <Badge variant="info" className="mx-2">
            {allItemsCount}
          </Badge>
          <span className="sr-only">total items</span>
        </Button>
      </ButtonGroup>
      {isExistingOG && (
        <ButtonGroup className="mr-2" aria-label="First group">
          <Button variant="outline-info" size="sm">
            <Image
              src={CutAndDryLogo}
              rounded={true}
              width={24}
              className="mx-1"
            />
            Existing Items
            <Badge variant="info" className="mx-2">
              {existingCount}
            </Badge>
            <span className="sr-only">existing items</span>
          </Button>
        </ButtonGroup>
      )}
      {isExistingOG && (
        <ButtonGroup className="mr-2" aria-label="Second group">
          <Button variant="outline-info" size="sm">
            <FontAwesomeIcon icon={faPen} className="mx-1 text-primary" />
            Existing Edit Items
            <Badge variant="info" className="mx-2">
              {existingEditedCount}
            </Badge>
            <span className="sr-only">existing edited items</span>
          </Button>
        </ButtonGroup>
      )}
      {isExistingOG && (
        <ButtonGroup className="mr-2" aria-label="Third group">
          <Button variant="outline-info" size="sm">
            New Items{" "}
            <Badge variant="info" className="mx-2">
              {newCount}
            </Badge>
            <span className="sr-only">new items</span>
          </Button>
        </ButtonGroup>
      )}
    </ButtonToolbar>
  );
}

OGLineItemSummary.propTypes = {
  lineItems: PropTypes.array.isRequired,
  isExistingOG: PropTypes.bool.isRequired
};
export default OGLineItemSummary;
