import { useState, useEffect } from "react";
import { get } from "networking/http";
import { GET_VERIFIED_ITEMS_BY_VENDOR_ID_OG } from "config/config";

const useOrderGuideVerifiedItems = (vendorID) => {
  const [verifiedItems, setVerifiedItems] = useState([]);
  const [verifiedItemNameList, setVerifiedItemNameList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVerifiedItems = async() => {
      try {
        const res = await get(
          `${GET_VERIFIED_ITEMS_BY_VENDOR_ID_OG}${vendorID}`
        );
        const data = await res.data;
        setVerifiedItems(data?.verified_og_items);
        setVerifiedItemNameList(
          data?.verified_og_items.length > 0
            ? data?.verified_og_items.map((item) => item.item_name)
            : []
        );
      } catch (error) {
        setError(error);
      }
    };
    fetchVerifiedItems();
  }, [vendorID]);
  return [verifiedItems, verifiedItemNameList, error];
};

export { useOrderGuideVerifiedItems };
