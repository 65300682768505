import { useState, useEffect } from "react";
import { get } from "networking/http";

const useGet = url => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await get(url);
        const data = await res.data;
        setResponse(data);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [url]);
  return [response, error];
};

export { useGet };
