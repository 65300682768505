import { useState } from 'react';

import { orderBy } from 'lodash';

import { useProperCase } from 'lib/hooks/useProperCase';

export function useOGLineItems() {
  const [lineItems, setLineItems] = useState();
  const setProperCase = useProperCase();
  return [
    lineItems,
    /**
     * updates line item input type text values on change
     * @param {Object} event - current event
     */
    function(event) {
      const allItems = [...lineItems];
      allItems[event.target.dataset.id][event.target.name] = event.target.value;
      setLineItems(allItems);
    },
    /**
     * add a new key named "proper"
     * @param {*} items
     */
    function(items) {
      items.map(item => {
        return (item['proper'] = false);
      });
      setLineItems(items);
    },
    /**
     * push a sample line item
     * @param {*} sampleLineItem
     */
    function(sampleLineItem) {
      const allItems = [];
      allItems.push(sampleLineItem);
      setLineItems(allItems);
    },
    /**
     * handles onChange option of the auto suggest field
     * @param {*} event
     * @param {*} index
     * @param {*} item
     * @param {*} verifiedItem
     * @param {*} lineItemNames
     * @param {*} isExistingOG
     */
    function(event, index, item, verifiedItems, lineItemNames, isExistingOG) {
      const allItems = [...lineItems];
      if (event) {
        if (item === 'item_name') {
          let found = verifiedItems.find(element => {
            return element.item_name === event;
          });
          lineItemNames.map(name => {
            return (allItems[index][name] = found?.[name]);
          });
          allItems[index]['verified'] = true;
        } else {
          allItems[index][item] = event;
        }
      } else {
        allItems[index][item] = '';
        if (item === 'item_name') {
          allItems[index]['verified'] = false;
        }
      }
      if (isExistingOG && allItems[index]['existing_og']) {
        allItems[index]['is_edited'] = true;
      }

      setLineItems(allItems);
    },
    /**
     * handle onCreate option of the auto suggest field
     * @param {*} value
     * @param {*} index
     * @param {*} item
     * @param {*} isExistingOG
     */
    function(value, index, item, isExistingOG) {
      const allItems = [...lineItems];
      if (item === 'item_name') {
        allItems[index][item] = allItems[index]['proper'] 
          ? value.trim() 
          : setProperCase(value.trim());
        allItems[index]['verified'] = false;
        if (isExistingOG && allItems[index]['existing_og']) {
          allItems[index]['is_edited'] = true;
        }
      } else if (item === 'section') {
        allItems[index][item] = value.trim();
      } else {
        allItems[index][item] = value.trim();
      }
      setLineItems(allItems);
    },
    /**
     * add new line item to given index
     * @param {*} index
     * @param {*} sampleLineItem
     */
    function(index, sampleLineItem) {
      let allItems = [...lineItems];
      allItems.splice(index, 0, sampleLineItem);
      setLineItems(allItems);
    },
    function(index) {
      let allItems = [...lineItems];
      allItems.splice(index, 1);
      setLineItems(allItems);
    },
    /**
     * toggle proper status
     * @param {*} index
     */
    function(index) {
      let allItems = [...lineItems];
      allItems[index]['proper'] = !allItems[index]['proper'];
      setLineItems(allItems);
    },
    setLineItems,
    function(index) {
      let allItems = orderBy(lineItems, "item_name");
      setLineItems(allItems);
    },
  ];
}
