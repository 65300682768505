
export function toTop() {
  window.scrollTo(0, 0);
}

function toQuerySelector(selector, offset = 0, setAnInterval = true) {

  const elem = window.document.querySelector(selector);

  if (elem) {
    window.scrollTo(0, elem.getBoundingClientRect().top + window.scrollY - offset);
  }

  // when more items are loading then the item gets automatically scrolled up
  if (setAnInterval) {
    var interval = window.setInterval(function() {
      if (window.document.readyState === 'complete') {
        window.clearInterval(interval);
        const element = window.document.querySelector(selector);
        if (element) {
          window.scrollTo(0, element.getBoundingClientRect().top + window.scrollY - offset);
        }
      }
    }, 1000);
  }
}

export default toQuerySelector;
