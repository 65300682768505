import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.validity.valid
          ? event.target.value
          : fields[event.target.id]
      });
    },
    setValues
  ];
}
