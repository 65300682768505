import Swal from "sweetalert2";

const SwalAlert = (type, message) => {
  Swal.fire({
    position: "center",
    icon: type,
    title: message,
    showConfirmButton: false,
    timer: 1500
  });
};

const WarningAlert = (
  onHandle,
  title,
  message,
  confirmButtonText,
  confirmationTitle
) => {
  Swal.fire({
    title: confirmationTitle,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmButtonText
  }).then(result => {
    if (result.value) {
      onHandle();
      SwalAlert("success", message);
    }
  });
};

const MessageAlert = (type, title, text, footer) => {
  Swal.fire({
    icon: type,
    title: title,
    text: text,
    footer: footer
  });
};

const AreYouSureAlert = (
  onHandle,
  confirmationTitle,
  message,
  confirmButtonText
) => {
  Swal.fire({
    title: confirmationTitle,
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmButtonText
  }).then(async result => {
    if (result.value) {
      onHandle();
    }
  });
};

export { SwalAlert, WarningAlert, MessageAlert, AreYouSureAlert };
