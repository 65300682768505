import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { css } from 'aphrodite';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';

import STATUS_OPTIONS from 'lib/const/status';
import { colorStyles } from 'lib/css/colors';

function SummaryGridView({ summaryData = {}, summaryKeyList = [] }) {
  const [summary, setSummary] = useState(summaryData);

  useEffect(() => {
    setSummary(summaryData);
  }, [summaryData]);

  if (!summary) {
    return <div className="my-3 border text-center">Summary count generation failed</div>;
  }

  return (
    <Row className="d-flex border my-3 shadow-sm">
      {summaryKeyList.map((summaryKey, index) => (
        <Col className="text-center" key={index}>
          <h5 className={classNames(css(colorStyles.steel), 'text-uppercase mt-1')}>
            {summaryKey === 'total' ? summaryKey : STATUS_OPTIONS[summaryKey].label}
          </h5>
          <h1> {summary[summaryKey]}</h1>
        </Col>
      ))}
    </Row>
  );
}

SummaryGridView.propTypes = {
  summaryData: PropTypes.object,
  summaryKeyList: PropTypes.array.isRequired,
};

export default SummaryGridView;
