import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ColumnResizer from 'react-column-resizer';
import { css, StyleSheet } from 'aphrodite';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  faClone,
  faPen,
  faPlusSquare,
  faTimes,
  faToggleOn,
  faToggleOff,
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

import ButtonGrouped from 'components/shared/Buttons/ButtonGrouped';
import CutAndDryLogo from 'asset/images/cutanddry.svg';
import CustomAutoSuggest from 'components/shared/CustomAutoSuggest';
import { keyCodes } from 'lib/ui/enums/enums';
import { useNewItem } from 'lib/hooks/orderGuides/useNewItem';
import { LINE_ITEMS_SPECIAL_COLUMNS } from 'lib/ui/enums/orderGuides';

function OGDataGridLineItems({
  lineItems,
  lineItemNames,
  uomList,
  sectionList,
  verifiedItemNames,
  isExistingOG,
  createNewProduct,
  updateProduct,
  updateOtherLineItemValues,
  addNewRow,
  removeRow,
  toggleProper,
  verifiedItems,
  isClaimEnable,
  isAutoCategorised,
  brands,
  initializeTempLineItems,
}) {
  const [createSampleLineItem, copyLineItem] = useNewItem();
  const getLineItemActions = index => {
    let actions = [
      {
        variant: 'outline-link',
        text: '',
        disabled: isClaimEnable,
        className: 'text-primary',
        giphy: { icon: faClone, className: 'text-secondary' },
        onHandle: addNewRow.bind(null, index + 1, copyLineItem(lineItems[index], lineItemNames)),
      },
      {
        variant: 'outline-link',
        text: '',
        className: 'text-primary',
        disabled: isClaimEnable,
        giphy: { icon: faPlusSquare, className: '' },
        onHandle: addNewRow.bind(
          null,
          index + 1,
          createSampleLineItem(isExistingOG, lineItemNames),
        ),
      },
    ];
    if (lineItems.length > 1) {
      actions.unshift({
        variant: 'outline-link',
        text: '',
        disabled: isClaimEnable,
        className: 'text-danger',
        giphy: { icon: faTimes, className: '' },
        onHandle: removeRow.bind(null, index),
      });
    }
    return actions;
  };

  const checkDuplicateItems = item => {
    if (item.trim() === '') {
      return '';
    }
    let found = lineItems.filter(element => {
      return element.item_name === item.trim();
    });
    return found.length > 1 ? 'border border-warning' : '';
  };

  //move an item in the list
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!result.destination || result.destination.index === result.source.index) {
        return;
      }
      const reorderedItems = reorder(lineItems, result.source.index, result.destination.index);
      //set the index of each item again.
      reorderedItems.forEach((part, index, theArray) => {
        theArray[index].sortIndex = index;
      });
      initializeTempLineItems([...reorderedItems]);
    },
    [lineItems, initializeTempLineItems],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} id="test" className="mx-0">
      <Droppable droppableId="lineItems">
        {provided => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {lineItems.length > 0 &&
              lineItems.map((item, index) => (
                <Draggable key={`${item}-${index}`} draggableId={`${item}-${index}`} index={index}>
                  {provided => (
                    <tr
                      key={`${item}-${index}`}
                      disabled={isClaimEnable}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <td className="text-center">
                        <ButtonGroup aria-label="First group">
                          <Button variant="outline-link" size="sm">
                            <Badge variant={lineItems[index]['verified'] ? 'success' : 'light '}>
                              {index + 1}
                            </Badge>
                          </Button>
                          {isExistingOG && (
                            <Button variant="outline-link" size="sm">
                              {lineItems[index]['existing_og'] && (
                                <Image src={CutAndDryLogo} rounded={true} width={18} height={18} />
                              )}
                            </Button>
                          )}
                          {lineItems[index]['is_edited'] && (
                            <Button variant="outline-link" size="sm">
                              <FontAwesomeIcon icon={faPen} className="mx-1 text-primary" />
                            </Button>
                          )}
                        </ButtonGroup>
                      </td>
                      <td>
                        <ButtonGrouped
                          buttons={getLineItemActions(index)}
                          size="sm"
                          btnGroupClassName=""
                        />
                      </td>
                      {lineItemNames.map((itemElement, idx) => (
                        <td
                          key={`${itemElement}-${index}-${idx}`}
                          className="oneLineToggle"
                        >
                          {itemElement === 'item_name' && (
                            <Form.Row>
                              <Form.Group
                                as={Col} sm={10}
                                controlId="itemList"
                                data-tip={lineItems[index][itemElement]}
                              >
                                <CustomAutoSuggest
                                  options={verifiedItemNames}
                                  defaultValue={lineItems[index][itemElement]}
                                  styles={checkDuplicateItems(lineItems[index][itemElement])}
                                  onCreate={e => {
                                    createNewProduct(e, index, itemElement, isExistingOG);
                                  }}
                                  onUpdate={e => {
                                    updateProduct(
                                      e,
                                      index,
                                      itemElement,
                                      verifiedItems,
                                      lineItemNames,
                                      isExistingOG,
                                    );
                                  }}
                                  keyPressOption={e => {
                                    e.preventDefault();
                                    addNewRow(
                                      index + 1,
                                      createSampleLineItem(isExistingOG, lineItemNames),
                                    );
                                  }}
                                  isClaimEnable={isClaimEnable}
                                  isBorderEnable={checkDuplicateItems(
                                    lineItems[index][itemElement],
                                  )}
                                />
                                <ReactTooltip type="info" effect="solid" />

                              </Form.Group>
                              <ColumnResizer className={css(styles.columnResizer)} minWidth={5} />
                              <Form.Group as={Col} sm={1} controlId="toggleItemName">
                                {lineItems[index]['proper'] ? (
                                  <FontAwesomeIcon
                                    icon={faToggleOff}
                                    className="text-danger"
                                    onClick={e => {
                                      e.preventDefault();
                                      toggleProper(index);
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faToggleOn}
                                    className="text-success"
                                    onClick={e => {
                                      e.preventDefault();
                                      toggleProper(index);
                                    }}
                                  />
                                )}
                              </Form.Group>
                            </Form.Row>
                          )}
                          {itemElement === 'brand' && (
                            <CustomAutoSuggest
                              value={lineItems[index][itemElement]}
                              options={brands.map(brand => brand.name)}
                              onCreate={e => {
                                createNewProduct(e, index, itemElement, isExistingOG);
                              }}
                              onUpdate={e => {
                                updateProduct(e, index, itemElement, [], [], false);
                              }}
                              keyPressOption={e => {
                                e.preventDefault();
                                addNewRow(
                                  index + 1,
                                  createSampleLineItem(isExistingOG, lineItemNames),
                                );
                              }}
                              isClaimEnable={isClaimEnable}
                            />
                          )}
                          {itemElement === 'section' && (
                            <CustomAutoSuggest
                              options={sectionList}
                              defaultValue={
                                isAutoCategorised && lineItems[index]['l1_section']
                                  ? lineItems[index]['l1_section']
                                  : lineItems[index][itemElement]
                              }
                              styles="text-capitalize"
                              onCreate={e => {
                                createNewProduct(e, index, itemElement, isExistingOG);
                              }}
                              onUpdate={e => {
                                updateProduct(e, index, itemElement, [], [], false);
                              }}
                              keyPressOption={e => {
                                e.preventDefault();
                                addNewRow(
                                  index + 1,
                                  createSampleLineItem(isExistingOG, lineItemNames),
                                );
                              }}
                              isClaimEnable={isClaimEnable}
                            />
                          )}
                          {itemElement === 'uom' && (
                            <CustomAutoSuggest
                              options={uomList}
                              defaultValue={lineItems[index][itemElement]}
                              styles="text-capitalize"
                              onCreate={e => {
                                createNewProduct(e, index, itemElement, isExistingOG);
                              }}
                              onUpdate={e => {
                                updateProduct(e, index, itemElement, [], [], false);
                              }}
                              keyPressOption={e => {
                                e.preventDefault();
                                addNewRow(
                                  index + 1,
                                  createSampleLineItem(isExistingOG, lineItemNames),
                                );
                              }}
                              isClaimEnable={isClaimEnable}
                            />
                          )}

                          {!LINE_ITEMS_SPECIAL_COLUMNS.includes(itemElement) && (
                            <OverlayTrigger
                              key={`${itemElement}-${index}`}
                              placement={'top'}
                              overlay={
                                <Tooltip id={`${itemElement}-${index}-tooltip-id-${idx}`}>
                                  <strong>
                                    {lineItems[index][itemElement]
                                      ? lineItems[index][itemElement]
                                      : ''}
                                  </strong>
                                </Tooltip>
                              }
                            >
                              <Form.Control
                                type="text"
                                value={lineItems[index][itemElement] || ''}
                                name={itemElement}
                                onChange={updateOtherLineItemValues}
                                data-id={index}
                                autoComplete="off"
                                onKeyDown={e => {
                                  if (e.keyCode === keyCodes.enter && e.ctrlKey) {
                                    addNewRow(
                                      index + 1,
                                      createSampleLineItem(isExistingOG, lineItemNames),
                                    );
                                  }
                                }}
                                readOnly={isClaimEnable}
                              />
                            </OverlayTrigger>
                          )}
                        </td>
                      ))}
                    </tr>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const styles = StyleSheet.create({
  columnResizer: {
    cursor: 'faExpandArrowsAlt',
    userSelect: 'none',
  },
  oneLineToggle: {
    whiteSpace: 'nowrap',
  },
});

OGDataGridLineItems.propTypes = {
  lineItems: PropTypes.array.isRequired,
  lineItemNames: PropTypes.array.isRequired,
  uomList: PropTypes.array.isRequired,
  sectionList: PropTypes.array.isRequired,
  verifiedItemNames: PropTypes.array.isRequired,
  isExistingOG: PropTypes.bool.isRequired,
  createNewProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  updateOtherLineItemValues: PropTypes.func.isRequired,
  addNewRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  toggleProper: PropTypes.func.isRequired,
  isClaimEnable: PropTypes.bool.isRequired,
  isAutoCategorised: PropTypes.bool.isRequired,
  brands: PropTypes.array.isRequired,
  initializeTempLineItems: PropTypes.func.isRequired,
};

export default OGDataGridLineItems;
