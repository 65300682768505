import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faSave, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import Swal from 'sweetalert2';
import 'react-overlay-loader/styles.css';
import { useHistory } from 'react-router-dom';

import ButtonGroupToolBar from 'components/shared/Buttons/ButtonGroupToolBar';
import exportToXlsx from 'lib/methods/exportXlsx';
import { get, put } from 'networking/http';
import {
  GET_ORDER_GUIDE_UOMS,
  GET_ORDER_GUIDE_SECTIONS,
  FETCH_BRANDS,
  UPDATE_ORDER_GUIDE_BY_ID,
} from 'config/config';
import {
  LINE_ITEM_NAMES_REMOVABLE_COLUMNS,
  LINE_ITEMS_SPECIAL_COLUMNS,
} from 'lib/ui/enums/orderGuides';
import OGDataGridLineItems from 'components/order-guides/OGDataGridLineItems';
import OGLineItemSummary from 'components/order-guides/OGLineItemSummary';
import { ogStatus } from 'lib/ui/enums/enums';
import { SwalAlert } from 'lib/ui/alert';
import { SwalToast } from 'lib/ui/toast';
import { useGet } from 'lib/hooks/useGet';
import { useNewItem } from 'lib/hooks/orderGuides/useNewItem';
import { useOGLineItems } from 'lib/hooks/orderGuides/useOGLineItems';
import { useOrderGuideVerifiedItems } from 'lib/hooks/useOrderGuideVerifiedItems';
import UserContext from 'components/layout/UserContext';
function OGDataGrid({ orderGuide, startTime, isClaimEnable, setOrderGuide }) {
  const [isLoading, setIsLoading] = useState(false);
  const [section, sectionFetchError] = useGet(GET_ORDER_GUIDE_SECTIONS);
  const [uom, uomFetchError] = useGet(GET_ORDER_GUIDE_UOMS);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [brands, setBrands] = useState([]);
  const currentUser = useContext(UserContext);
  const [isAutoCategorised, setIsAutoCategorised] = useState(false);
  const [createSampleLineItem] = useNewItem();
  const [verifiedItems, verifiedItemName, verifiedItemError] = useOrderGuideVerifiedItems(
    orderGuide.vendor_id,
  );
  const history = useHistory();

  const [
    tempLineItems,
    setTempLineItems,
    setProperKey,
    setSampleLineItem,
    onSelectupdateTempLineItems,
    onCreateUpdateTempLineItems,
    addNewLineItem,
    removeLineItem,
    toggleProper,
    initializeTempLineItems,
    sortLineItems,
  ] = useOGLineItems();

  const DisplayingHeaders = {
    item_name: 'Item Name',
    brand: 'Brand',
    item_code: 'Item Code',
    unit_price: 'Unit Price',
    uom: 'Purchasing Unit',
    accounting_category: 'Accounting Category',
    par: 'Par',
    section: 'Section',
  };

  /**
   * display toast msgs for the sections and uom fething errors
   */
  const displayListFetchErrors = () => {
    if (sectionFetchError || uomFetchError || verifiedItemError) {
      let type = '';
      let errorCode = '';
      if (sectionFetchError) {
        type = 'sections';
        errorCode = sectionFetchError.message;
      } else if (uomFetchError) {
        type = 'purchasing units';
        errorCode = uomFetchError.message;
      } else if (verifiedItemError) {
        type = 'verified items for vendor';
        errorCode = verifiedItemError.message;
      }

      SwalToast('error', `Failed to reterive ${type} due to ${errorCode}`);
    }
  };

  /**
   * run loading screen since order guide data is assigned
   * if order guide status is pending, update status to processing status
   */
  useEffect(() => {
    setIsLoading(true);
    setOGDetails();
    displayListFetchErrors();
    setIsLoading(false);
    getBrands();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getBrands = () => {
    get(FETCH_BRANDS).then(res => {
      if (res.data) {
        setBrands(res.data.brands);
      }
    });
  };

  /**
   * set order guide line items,table header and line item names
   * -- assigning line items
   *        if already line items are available assign the line items
   *        with adding a new key as "proper" to `lineItems` variable
   *        else create sample line item object and push to original
   *        line items array
   * -- assigning table headers
   *        remove non display columns given in "removableColumns" array
   *        and assign the other column names to `tableHeaders` variable
   * -- assigning line item names
   *        assign all line item names in given order guide
   *        under `line_item_names` key
   */
  const setOGDetails = () => {
    setIsLoading(true);
    if (orderGuide?.line_items.length > 0) {
      orderGuide.line_items.forEach((item, index) => {
        if (!Object.keys(item).includes('section')) {
          item['section'] = '';
          orderGuide.line_items[index] = item;
        }
      });
      initializeTempLineItems(orderGuide.line_items);
      setProperKey(orderGuide.line_items);
    } else {
      setSampleLineItem(createSampleLineItem(orderGuide?.have_og, orderGuide?.line_item_names));
    }
    let headers = orderGuide?.line_item_names;
    LINE_ITEM_NAMES_REMOVABLE_COLUMNS.map(item => {
      const index = headers.indexOf(item);
      if (index > -1) {
        headers.splice(index, 1);
      }
      return headers;
    });
    setTableHeaders(headers);
    setIsLoading(false);
  };

  //remove selected row from lineitems
  const removeRow = index => {
    const removeItem = tempLineItems[index]['item_name'];
    Swal.fire({
      title: 'Are you sure?',
      text: `${removeItem}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        removeLineItem(index);
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: `${removeItem} has been removed.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const handleSave = () => {
    let endTime = new Date();
    let crunchingTime = (endTime - startTime) / 1000; //diff given in mili seconds
    if (Number(orderGuide?.crunch_time) > 0) {
      crunchingTime = Number(orderGuide?.crunch_time) + Number(crunchingTime);
    }
    put(`${UPDATE_ORDER_GUIDE_BY_ID}${orderGuide._id}`, {
      line_items: removeProperItem(tempLineItems),
      user_email: currentUser?.ocr_user?.name,
      crunch_time: crunchingTime,
    })
      .then(res => {
        if (res.data) {
          SwalAlert('success', 'Saved successfully!');
        } else {
          SwalAlert('error', 'Failed to save!');
        }
      })
      .catch(err => {
        SwalAlert('error', err.message);
      });
  };

  const updateStatus = status => {
    let endTime = new Date();
    let totalTime = (endTime - startTime) / 1000; //diff given in mili seconds

    if (status === 'processed') {
      if (Number(orderGuide?.crunch_time) > 0) {
        totalTime = Number(orderGuide?.crunch_time) + Number(totalTime);
      }
    } else if (status === 'verified') {
      if (Number(orderGuide?.review_time) > 0) {
        totalTime = Number(orderGuide?.review_time) + Number(totalTime);
      }
    }

    let data = null;
    if (status === 'processed') {
      data = {
        vendor_id: orderGuide.vendor_id,
        status: status,
        line_items: removeProperItem(tempLineItems),
        user_email: currentUser?.ocr_user?.name,
        crunch_time: totalTime,
      };
    } else if (status === 'verified') {
      data = {
        vendor_id: orderGuide.vendor_id,
        status: status,
        line_items: removeProperItem(tempLineItems),
        reviewed_user_email: currentUser?.ocr_user?.name,
        review_time: totalTime,
      };
    } else if (status === 'published') {
      data = {
        is_archived: true,
        status: status,
      };
    }
    put(`${UPDATE_ORDER_GUIDE_BY_ID}${orderGuide._id}`, data)
      .then(res => {
        if (res.data) {
          const og = res.data?.output_order_guide;
          setOrderGuide(og);
          initializeTempLineItems(og?.line_items);
          SwalAlert('success', 'Saved successfully!');
          if (status === 'published') {
            history.push('/order-guide/dashboard');
          }
        } else {
          SwalAlert('error', 'Failed to save!');
        }
      })
      .catch(err => {
        SwalAlert('error', err.message);
      });
  };

  const groupSections = items => {
    return items.reduce((result, currentValue) => {
      (result[currentValue.section] = result[currentValue.section] || []).push(currentValue);
      return result;
    }, {});
  };

  const makeExcelData = items => {
    let excelData = [];
    const groupedItems = groupSections(items);
    Object.keys(groupedItems)
      .sort()
      .map(key => {
        //adding section item
        if (key !== '') {
          excelData.push(
            setExcelColumnData({
              item_name: key.toUpperCase(),
              item_code: 'Section',
            }),
          );
        }
        //sorting grouped items item name in ascending order
        groupedItems[key].sort(sortGroupedItems);
        groupedItems[key].map(groupedItem => {
          return excelData.push(setExcelColumnData(groupedItem));
        });
        return excelData;
      });
    return excelData;
  };

  const setExcelColumnData = data => {
    let sectionInnerObj = {};
    sectionInnerObj['Item Name'] = data['item_name'];
    sectionInnerObj['Purchasing Unit'] = data['uom'];
    sectionInnerObj['Unit Price'] = data['unit_price'];
    sectionInnerObj['Item Code'] = data['item_code'];
    sectionInnerObj['Accounting Category'] = data['accounting_category'];
    sectionInnerObj['Par'] = data['par'];
    return sectionInnerObj;
  };

  const sortGroupedItems = (itemA, itemB) => {
    return itemA.item_name.toUpperCase() > itemB.item_name.toUpperCase() ? 1 : -1;
  };

  const getWidth = column => {
    if (column === 'item_name') {
      return '300px';
    }
    if (column === 'brand') {
      return '100px';
    }
    if (column === 'section') {
      return '100px';
    } else if (column === 'uom') {
      return '30px';
    } else if (!LINE_ITEMS_SPECIAL_COLUMNS.includes(column)) {
      return '50px';
    }
  };

  const removeProperItem = allItems => {
    return allItems.map(({ proper, ...rest }) => rest);
  };

  const OnAutoCategorised = () => {
    setIsAutoCategorised(!isAutoCategorised);
  };

  return (
    <LoadingOverlay>
      <Container className={css(styles.tableContainer)} >
        <Table hover className={css(styles.lineItemTable)} size="sm">
          <thead>
            <tr>
              <th width="20px" className="text-center">
                #
              </th>
              <th width="5%"></th>
              {tableHeaders.map(header => (
                <th key={header} className="text-capitalize" width={getWidth(header)}>
                  {DisplayingHeaders[header]}
                </th>
              ))}
            </tr>
          </thead>
          {uom && verifiedItemName && tempLineItems && (
            <OGDataGridLineItems
              lineItems={tempLineItems}
              lineItemNames={tableHeaders}
              uomList={uom}
              sectionList={section ? section : []}
              verifiedItemNames={verifiedItemName}
              isExistingOG={orderGuide?.have_og}
              createNewProduct={onCreateUpdateTempLineItems}
              updateProduct={onSelectupdateTempLineItems}
              updateOtherLineItemValues={setTempLineItems}
              addNewRow={addNewLineItem}
              removeRow={removeRow}
              toggleProper={toggleProper}
              verifiedItems={verifiedItems}
              isClaimEnable={isClaimEnable}
              isAutoCategorised={isAutoCategorised}
              brands={brands}
              initializeTempLineItems={initializeTempLineItems}
            />
          )}
        </Table>
      </Container>
      <Row className="justify-content-end my-2">
        {tempLineItems && (
          <ButtonGroupToolBar
            buttons={[
              {
                variant: 'outline-success',
                text: 'Alphabetize',
                disabled: isClaimEnable,
                onHandle: sortLineItems.bind(null),
              },
              {
                variant: isAutoCategorised ? 'success' : 'outline-success',
                text: isAutoCategorised ? 'upmap sections' : 'map sections',
                disabled:
                  orderGuide.status === ogStatus.crunching ||
                  orderGuide.status === ogStatus.pending,
                className: '',
                onHandle: OnAutoCategorised.bind(null),
              },
              {
                variant: 'outline-success',
                text: 'download',
                disabled: isClaimEnable,
                className: '',
                giphy: { icon: faDownload, className: '' },
                onHandle: exportToXlsx.bind(
                  null,
                  makeExcelData(tempLineItems),
                  `${orderGuide.operator_name}-${orderGuide.vendor_name}
                    -${orderGuide.order_guide_id}`,
                ),
              },
              {
                variant: 'outline-success',
                text: 'publish',
                disabled: isClaimEnable,
                className: '',
                giphy: { icon: faShare, className: '' },
                onHandle: updateStatus.bind(null, ogStatus.published),
              },
              {
                variant: 'outline-success',
                text: 'reviewed',
                disabled: isClaimEnable,
                className: '',
                giphy: { icon: faCheck, className: '' },
                onHandle: updateStatus.bind(null, ogStatus.reviewed),
              },
              {
                variant: 'outline-success',
                text: 'crunched',
                disabled: isClaimEnable,
                className: '',
                giphy: { icon: faThumbsUp, className: '' },
                onHandle: updateStatus.bind(null, ogStatus.crunched),
              },
              {
                variant: 'success',
                text: 'save',
                disabled: isClaimEnable,
                className: '',
                giphy: { icon: faSave, className: '' },
                onHandle: handleSave.bind(null),
              },
            ]}
            size="sm"
            btnGroupClassName="mr-2"
          />
        )}
      </Row>
      <Row>
        {tempLineItems && (
          <OGLineItemSummary lineItems={tempLineItems} isExistingOG={orderGuide?.have_og} />
        )}
      </Row>
      <Loader loading={isLoading} text={'Loading,Please wait...'} fullPage />
    </LoadingOverlay>
  );
}
const styles = StyleSheet.create({
  lineItemTable: {
    minWidth: '70vw',
    maxHeight: '70vh',
    overflowX: 'auto !important',
  },
  tableContainer: {
    minHeight: '80vh',
    maxHeight: '80vh',
    overflowX: 'auto !important',
  },
  tableHeader: {
    textTransform: 'capitalize',
  },
});

OGDataGrid.propTypes = {
  orderGuide: PropTypes.object.isRequired,
  startTime: PropTypes.object.isRequired,
  isClaimEnable: PropTypes.bool.isRequired,
  setOrderGuide: PropTypes.func.isRequired,
};
export default OGDataGrid;
