import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Form, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { intersection } from 'lodash-es';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';

import ButtonGrouped from 'components/shared/Buttons/ButtonGrouped';
import CustomAutoSuggest from 'components/shared/CustomAutoSuggest';
import { getLineTotal, getSum } from 'lib/helpers/invoice/invoice';
import { INPUT_VALID_PATTERN, keyCodes } from 'lib/ui/enums/enums';
import { SAVE_BTN_HIDDEN_STATUS } from 'components/invoices/crunch/Details';
import STATUS_OPTIONS from 'lib/const/status';
import { SwalAlert } from 'lib/ui/alert';

const formatString = label => {
  return label.replace(/_/g, ' ').replace('/', '');
};

function Items({
  activeTab,
  invoice,
  lineItems,
  setLineItems,
  isClaimed,
  verifiedItems,
  productCodes,
  subtotal,
  mapping,
  user,
  updateInvoiceHandler,
  brands,
}) {
  const isSaveBtnVisible = useMemo(() => {
    if (user) {
      return (
        !SAVE_BTN_HIDDEN_STATUS.includes(invoice.status) &&
        invoice.crunched_by === user.ocr_user.name &&
        !isClaimed
      );
    }
  }, [invoice, user, isClaimed]);

  const getSampleLineItem = (isCopy, lineItem) => {
    return invoice.line_item_names.reduce(
      (o, key) => ({ ...o, [key]: isCopy ? lineItem[key] : '' }),
      {},
    );
  };

  const addItem = (isCopy, index) => {
    let allItems = [...lineItems];
    allItems.splice(index + 1, 0, getSampleLineItem(isCopy, lineItems[index]));
    setLineItems(allItems);
  };

  const removeItem = index => {
    Swal.fire({
      title: `Are you sure want to remove?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then(result => {
      if (result.value) {
        let allItems = [...lineItems];
        allItems.splice(index, 1);
        setLineItems(allItems);
        SwalAlert('success', 'Item has been removed');
      }
    });
  };

  const removeAllItems = () => {
    Swal.fire({
      title: `Are you sure want to remove all items?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then(result => {
      if (result.value) {
        setLineItems([getSampleLineItem()]);
        SwalAlert('success', 'All Items have been removed');
      }
    });
  };

  const getLineItemActions = index => {
    let actions = [
      {
        variant: 'outline-link',
        text: '',
        disabled: isClaimed,
        className: 'text-primary',
        giphy: { icon: faClone, className: 'text-secondary' },
        onHandle: addItem.bind(null, true, index),
      },
      {
        variant: 'outline-link',
        text: '',
        className: 'text-primary',
        disabled: isClaimed,
        giphy: { icon: faPlusSquare, className: '' },
        onHandle: addItem.bind(null, false, index),
      },
    ];
    if (lineItems.length > 1) {
      actions.unshift({
        variant: 'outline-link',
        text: '',
        disabled: isClaimed,
        className: 'text-danger',
        giphy: { icon: faTimes, className: '' },
        onHandle: removeItem.bind(null, index),
      });
    }
    return actions;
  };

  const onCreateItems = (e, index, name) => {
    const allItems = [...lineItems];

    allItems[index][name] = e;

    if (name === mapping.qty_shipped || name === mapping.qty_ordered || name === mapping.price) {
      allItems[index][mapping.line_total] = getLineTotal(allItems, index, mapping);
    }
    setLineItems(allItems);
  };

  const onUpdateItem = (e, index, name) => {
    let allItems = [...lineItems];
    if (name === mapping.item_name) {
      const foundItem = verifiedItems[e];
      const updatableFields = intersection(invoice.line_item_names, Object.keys(foundItem));
      updatableFields.map(item => {
        if (Object.values(mapping).includes(item)) {
          allItems[index][item] = getTypes(item)
            ? Number(foundItem[item]).toFixed(2)
            : foundItem[item];
          if (
            item === mapping.qty_shipped ||
            name === mapping.qty_ordered ||
            item === mapping.price
          ) {
            allItems[index][mapping.line_total] = getLineTotal(allItems, index, mapping);
          }
        }
        return allItems;
      });
    } else if (name === mapping.item_code || name === mapping.brand) {
      allItems[index][name] = e;
    }
    setLineItems(allItems);
  };

  const getTypes = name => {
    if (name.includes('quantity') || name.includes('price')) {
      return true;
    }
    return false;
  };

  const getTableColumnWidth = name => {
    if (name === mapping.item_name) {
      return '20%';
    } else {
      return `${80 / invoice.line_item_names.length}%`;
    }
  };

  const getChecksum = index => {
    return !(
      Number(getLineTotal(lineItems, index, mapping)) ===
      Number(lineItems[index][mapping.line_total])
    )
      ? 'table-danger'
      : '';
  };

  const getInputPattern = name => {
    if (name.includes('quantity') || name.includes('price')) {
      return INPUT_VALID_PATTERN.FLOAT_SEVEN_DECIMALS_PATTERN;
    } else {
      return INPUT_VALID_PATTERN.ANY_CHARACTER_PATTERN;
    }
  };

  const getClassName = name => {
    if (name.includes('quantity') || name.includes('price') || name.includes('size')) {
      return classNames(css(styles.itemsInput), 'text-right');
    } else {
      return css(styles.itemsInput);
    }
  };

  //move an item in the list
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!result.destination || result.destination.index === result.source.index) {
        return;
      }
      const reorderedItems = reorder(lineItems, result.source.index, result.destination.index);
      setLineItems([...reorderedItems]);
    },
    [lineItems, setLineItems],
  );

  return (
    <div>
      <div className={css(styles.tableContainer)}>
        <DragDropContext onDragEnd={onDragEnd} id="test">
          <Table className={css(styles.lineItemTable)} size="sm">
            <thead>
              <tr>
                <th width="2%" className="text-center">
                  #
                </th>
                <th width="5%" className="justify-content-center"></th>
                {invoice.line_item_names.map((header, index) => (
                  <th className="text-capitalize" key={index} width={getTableColumnWidth(header)}>
                    {formatString(header)}
                  </th>
                ))}
              </tr>
            </thead>
            <Droppable droppableId="lineItems">
              {provided => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {lineItems.length > 0 &&
                    lineItems.map((item, idx) => (
                      <Draggable key={`${item}-${idx}`} draggableId={`${item}-${idx}`} index={idx}>
                        {provided => (
                          <tr
                            key={`${item}-${idx}`}
                            disabled={isClaimed}
                            className={getChecksum(idx)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td className="pt-2">{idx + 1}</td>
                            <td>
                              <ButtonGrouped
                                buttons={getLineItemActions(idx)}
                                size="sm"
                                btnGroupClassName=""
                              />
                            </td>

                            {invoice.line_item_names.map((name, iidx) => {
                              if (name === mapping.item_name) {
                                return (
                                  <td
                                    key={`${name}-${iidx}-${idx}`}
                                    data-tip={lineItems[idx][name]}
                                  >
                                    <CustomAutoSuggest
                                      options={Object.keys(verifiedItems)}
                                      fuzzySuggestions={lineItems[idx]['fuzzy_suggestions']}
                                      defaultValue={lineItems[idx][name]}
                                      styles={isClaimed ? 'form-control-plaintext' : 'form-control'}
                                      onCreate={e => {
                                        onCreateItems(e, idx, name);
                                      }}
                                      onUpdate={e => onUpdateItem(e, idx, name)}
                                      keyPressOption={e => {
                                        if (e.keyCode === keyCodes.enter && e.ctrlKey) {
                                          addItem(false, idx);
                                        }
                                      }}
                                      isClaimEnable={isClaimed}
                                      isBorderEnable={false}
                                    />
                                    <ReactTooltip type="info" effect="solid" />
                                  </td>
                                );
                              }
                              if (name === mapping.brand) {
                                return (
                                  <td key={`${name}-${iidx}-${idx}`}
                                  data-tip={lineItems[idx][name]}
                                  >
                                    <CustomAutoSuggest
                                      options={brands.map(brand => brand.name)}
                                      defaultValue={lineItems[idx][name]}
                                      styles={isClaimed ? 'form-control-plaintext' : 'form-control'}
                                      onCreate={e => {
                                        onCreateItems(e, idx, name);
                                      }}
                                      onUpdate={e => onUpdateItem(e, idx, name)}
                                      keyPressOption={e => {
                                        if (e.keyCode === keyCodes.enter && e.ctrlKey) {
                                          addItem(false, idx);
                                        }
                                      }}
                                      isClaimEnable={isClaimed}
                                      isBorderEnable={false}
                                    />
                                    <ReactTooltip type="info" effect="solid" />
                                  </td>
                                );
                              }
                              if (name === mapping.item_code) {
                                return (
                                  <td key={`${name}-${iidx}-${idx}`}
                                  data-tip={lineItems[idx][name]}>
                                    <CustomAutoSuggest
                                      options={productCodes}
                                      defaultValue={lineItems[idx][name]}
                                      styles={isClaimed ? 'form-control-plaintext' : 'form-control'}
                                      onCreate={e => {
                                        onCreateItems(e, idx, name);
                                      }}
                                      onUpdate={e => onUpdateItem(e, idx, name)}
                                      keyPressOption={e => {
                                        if (e.keyCode === keyCodes.enter && e.ctrlKey) {
                                          addItem(false, idx);
                                        }
                                      }}
                                      isClaimEnable={isClaimed}
                                      isBorderEnable={false}
                                    />
                                    <ReactTooltip type="info" effect="solid" />
                                  </td>
                                );
                              } else {
                                return (
                                  <td key={`${name}-${iidx}-${idx}`}
                                    data-tip={lineItems[idx][name] || ''}
                                  >
                                    <Form.Control
                                      type="text"
                                      pattern={getInputPattern(name)}
                                      data-id={idx}
                                      name={name}
                                      value={lineItems[idx][name] || ''}
                                      plaintext={isClaimed}
                                      readOnly={isClaimed}
                                      className={getClassName(name)}
                                      autoComplete="off"
                                      onChange={e => {
                                        return e.target.validity.valid
                                          ? onCreateItems(e.target.value, idx, name)
                                          : lineItems[idx][name];
                                      }}
                                      onKeyDown={e => {
                                        if (e.keyCode === keyCodes.enter && e.ctrlKey) {
                                          addItem(false, idx);
                                        }
                                      }}
                                    />
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                  <tr className="table-info">
                    <td></td>
                    <td className="font-weight-bold">Summary</td>
                    {invoice.line_item_names.map(name => {
                      if (
                        name === mapping.qty_shipped ||
                        name === mapping.qty_ordered ||
                        name === mapping.line_total
                      ) {
                        return (
                          <td className="text-right font-weight-bold" key={name}>
                            <Form.Label>{getSum(lineItems, name)}</Form.Label>
                          </td>
                        );
                      } else {
                        return <td key={name}></td>;
                      }
                    })}
                  </tr>
                  {isSaveBtnVisible && (
                    <tr>
                      <td colSpan={2}>
                        <Button
                          variant="warning"
                          onClick={() => removeAllItems()
                          }
                        >
                          Clear Line Items
                        </Button>
                      </td>
                      <td colSpan={invoice.line_item_names.length-1}></td>
                      <td>
                        <Button
                          block
                          className="font-weight-bold"
                          onClick={() =>
                            updateInvoiceHandler(activeTab, STATUS_OPTIONS.processing.value)
                          }
                        >
                          Save
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>

        <div>
          {Number(subtotal) !== Number(getSum(lineItems, mapping.line_total)) && (
            <Alert variant="warning">
              Warning: Checksum Failed. Please ensure that the order item total is matching the
              subtotal provided in the invoice details section and try again.
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  lineItemTable: {
    maxHeight: '70vh',
    width: '70vw',
    overflowX: 'auto !important',
  },
  itemsInput: {
    border: 'none',
    outline: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #616A6B',
    color: '#424949 ',
  },
  tableContainer: {
    maxHeight: '79vh',
    minHeight: '79vh',
    overflowX: 'auto !important',
    margin: '5px 5px',
  },
});

Items.propTypes = {
  activeTab: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  lineItems: PropTypes.array.isRequired,
  setLineItems: PropTypes.func.isRequired,
  isClaimed: PropTypes.bool.isRequired,
  subtotal: PropTypes.string.isRequired,
  mapping: PropTypes.object.isRequired,
  user: PropTypes.object,
  updateInvoiceHandler: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
};

export default Items;
